import { createTheme } from '@mui/material';
import { createBreakpoints } from '@mui/system';
import {
  tannerBlack,
  tannerBlue,
  tannerGray,
  tannerWhite,
} from '../../ThemeProvider/colors';

const useHeaderTheme = () => {
  const breakpoints = createBreakpoints({});

  const headerTheme = createTheme({
    typography: {
      htmlFontSize: 14,
      fontSize: 14,
      fontFamily: '"Noto Sans", sans-serif',
      fontWeightRegular: 500,
    },
    palette: {
      primary: {
        main: tannerBlack,
      },
      secondary: {
        main: tannerWhite,
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: 'none',
            fontSize: 14,
            fontWeight: 700,
          },
        },
      },
      MuiDrawer: {
        styleOverrides: {
          paper: {
            width: 280,
          },
        },
      },
      MuiIconButton: {
        defaultProps: {
          disableTouchRipple: true,
        },
        styleOverrides: {
          root: {
            color: tannerBlack,
            fontSize: 16,
          },
          colorSecondary: {
            color: tannerWhite,
            '&:hover': {
              backgroundColor: tannerGray['800'],
            },
            '&:active': {
              opacity: 0.5,
            },
          },
        },
      },
      MuiSvgIcon: {
        defaultProps: { fontSize: 'inherit', color: 'inherit' },
      },
      MuiTabs: {
        defaultProps: {
          indicatorColor: 'secondary',
          textColor: 'secondary',
        },
        styleOverrides: {
          indicator: {
            height: 5,
          },
        },
      },
      MuiTab: {
        defaultProps: {
          disableTouchRipple: true,
        },
        styleOverrides: {
          root: {
            '&:active [class^="MuiTouchRipple-root"]': {
              borderBottom: '5px solid rgba(255,255,255,0.5)',
              transition: 'border 200ms',
            },
            fontWeight: 700,
            fontSize: 14,
            height: 56,
            textTransform: 'none',
            [breakpoints.up('sm')]: {
              minWidth: 50,
            },
            '&:hover': {
              backgroundColor: tannerGray['800'],
            },
          },
          textColorSecondary: {
            color: tannerWhite,
          },
        },
      },
      MuiSkeleton: {
        styleOverrides: {
          root: {
            backgroundColor: 'rgba(200,200,200,0.25)',
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          body1: {
            fontSize: 14,
          },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          inputRoot: {
            flexWrap: 'initial',
            padding: 0,
            '& .MuiAutocomplete-input': {
              minWidth: 0,
            },
          },
          input: {
            fontSize: 14,
            '@media screen and (min-width: 760px)': {
              color: tannerWhite,
            },
          },
          listbox: {
            padding: 8,
            '& .MuiAutocomplete-option': {
              '@media screen and (min-width: 760px)': {
                '&.Mui-focused': {
                  backgroundColor: tannerGray['900'],
                },
                '&.Mui-focusVisible': {
                  outline: `3px solid ${tannerBlue['300']}`,
                  outlineOffset: 3,
                  transition: 'outline-offset 150ms',
                  borderRadius: 3,
                },
              },
            },
          },
          option: {
            '&.Mui-focused': {
              borderRadius: '3px',
            },
          },
          paper: {
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            margin: 0,
            '@media screen and (min-width: 769px)': {
              backgroundColor: tannerGray['800'],
            },
          },
        },
      },
    },
  });

  return headerTheme;
};

export default useHeaderTheme;
