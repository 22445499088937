import React, { forwardRef } from 'react';
import MuiStepper, {
  StepperProps as MuiStepperProps,
} from '@mui/material/Stepper';
import StepIcon from '../StepIcon';
import Connector from '../StepConnector';
import MuiStep from '@mui/material/Step';
import Step from '@mui/material/Step';
import { StyledStepLabel } from './styles';
interface Step {
  label: string;
  id?: string;
}
export type StepperProps = MuiStepperProps & {
  steps: Step[];
  onStepClick?(step: Step, index: number): void;
};

const Stepper = forwardRef<HTMLButtonElement, StepperProps>(
  ({ steps, activeStep = 0, onStepClick = () => {}, ...props }) => {
    return (
      <MuiStepper
        connector={<Connector />}
        {...props}
        alternativeLabel
        activeStep={activeStep}
        orientation="horizontal"
      >
        {steps.map((step, idx) => {
          return (
            <MuiStep
              key={step.label}
              aria-label={`${step.label} ${
                idx === activeStep ? ' current step' : ' step'
              }${idx + 1} of ${steps.length}`}
              tabIndex={idx <= activeStep ? 0 : -1}
              role={idx === activeStep ? 'none' : 'button'}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  onStepClick(step, idx);
                }
              }}
            >
              <StyledStepLabel
                StepIconComponent={StepIcon}
                onClick={() => {
                  if (idx < activeStep) {
                    onStepClick(step, idx);
                  }
                }}
              >
                <span className="label">{step.label}</span>
              </StyledStepLabel>
            </MuiStep>
          );
        })}
      </MuiStepper>
    );
  },
);

export default Stepper;
