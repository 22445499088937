import React from 'react';

import { FormControlLabelContainer, SubtextTypography } from './styles';
import { FormControlLabelProps } from './types';

const FormControlLabel: React.FC<FormControlLabelProps> = ({
  subtext,
  control,
  checked,
  classes,
  componentsProps,
  disabled,
  disableTypography,
  inputRef,
  label,
  labelPlacement = 'end',
  onChange,
  required,
  slotProps,
  name,
  value,
  id,
  tabIndex = 0,
  onClick,
  onFocus,
  onKeyDown,
  onBlur,
  ref,
  'aria-label': ariaLabel,
  'aria-labelledby': ariaLabelledBy,
  'aria-describedby': ariaDescribedBy,
  'aria-hidden': ariaHiddenBy,
  'aria-controls': ariaControls,
  sx,
  style,
  key,
  className,
  'data-testid': dataTestId,
}) => {
  return (
    <div>
      <FormControlLabelContainer
        control={control}
        checked={checked}
        classes={classes}
        componentsProps={componentsProps}
        disabled={disabled}
        disableTypography={disableTypography}
        inputRef={inputRef}
        tabIndex={tabIndex}
        label={
          subtext ? (
            <>
              {label}
              <SubtextTypography variant="body2">{subtext}</SubtextTypography>
            </>
          ) : (
            label
          )
        }
        labelPlacement={labelPlacement}
        onChange={onChange}
        onKeyDown={onKeyDown}
        required={required}
        slotProps={slotProps}
        value={value}
        sx={sx}
        style={style}
        className={className}
        key={key}
        id={id}
        data-testid={dataTestId}
        aria-label={ariaLabel}
        aria-labelledby={ariaLabelledBy}
        aria-describedby={ariaDescribedBy}
        aria-hidden={ariaHiddenBy}
        aria-controls={ariaControls}
        name={name}
        onClick={onClick}
        onFocus={onFocus}
        onBlur={onBlur}
        ref={ref}
      />
    </div>
  );
};

export default FormControlLabel;
