import styled from '@emotion/styled';
import { StepConnector as MuiStepConnector } from '@mui/material';
import { tannerGray } from '../ThemeProvider/colors';
import { useTheme } from '@mui/material/styles';

const Connector = styled(MuiStepConnector)(() => {
  const theme = useTheme();
  return {
    ' &.MuiStepConnector-alternativeLabel ': {
      top: 20,
      left: 'calc(-50% + 16px)',
      right: 'calc(50% + 16px)',
    },
    '& .MuiStepConnector-line': {
      borderColor: tannerGray[300],
      borderTopWidth: 2,
      borderRadius: 3,
      margin: '0px 12px',
    },
    '&.Mui-active': {
      '& .MuiStepConnector-line': {
        borderColor: theme.palette.primary.main,
      },
    },
    '&.Mui-completed': {
      '& .MuiStepConnector-line': {
        borderColor: theme.palette.primary.main,
      },
    },
    '@media (max-width: 480px)': {
      '&.MuiStepConnector-alternativeLabel': {
        top: 12,
        left: 'calc(-50% - 3px)',
        right: 'calc(50% - 3px)',
      },
      '&.MuiStepConnector-line': {
        borderColor: tannerGray[200],
      },
    },
  };
});

export default Connector;
