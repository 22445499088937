import { ThemeOptions, PaletteOptions } from '@mui/material/styles';
import { createBreakpoints } from '@mui/system';
import {
  tannerBlack,
  tannerBlue,
  tannerGray,
  tannerPurple,
  tannerSemantic,
  tannerWhite,
} from './colors';

const breakpoints = createBreakpoints({
  values: {
    xs: 0,
    sm: 361,
    md: 768,
    lg: 960,
    xl: 1200,
  },
});

const dialogShadow =
  '0px 1px 10px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.14)';

const themeOptions: ThemeOptions & {
  palette: PaletteOptions & {
    white: { main: string };
    black: { main: string };
    blue: { main: string };
  };
} = {
  typography: {
    htmlFontSize: 14,
    fontSize: 14,
    fontFamily: '"Noto Sans", sans-serif',
    fontWeightRegular: 500,
  },
  spacing: [0, 2, 4, 8, 12, 16, 24, 32],
  breakpoints,
  palette: {
    background: { default: tannerWhite, paper: tannerWhite },
    text: { primary: tannerBlack },
    primary: {
      main: tannerBlue['500'],
    },
    secondary: { main: tannerGray['200'] },
    error: { main: '#D70E16' },
    white: {
      main: tannerWhite,
    },
    black: {
      main: tannerBlack,
    },
    blue: {
      main: tannerBlue['500'],
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Noto Sans';
          src: url(https://oct.assets.appreciatehub.com/fonts/notosans/notosans-black-webfont.woff2), url(https://oct.assets.appreciatehub.com/fonts/notosans/notosans-black-webfont.woff);
          font-weight: 900;
          font-style: normal;
          font-display: swap;
        }
        @font-face {
          font-family: 'Noto Sans';
          src: url(https://oct.assets.appreciatehub.com/fonts/notosans/notosans-extrabold-webfont.woff2), url(https://oct.assets.appreciatehub.com/fonts/notosans/notosans-extrabold-webfont.woff);
          font-weight: 800;
          font-style: normal;
          font-display: swap;
        }
        @font-face {
          font-family: 'Noto Sans';
          src: url(https://oct.assets.appreciatehub.com/fonts/notosans/notosans-bold-webfont.woff2), url(https://oct.assets.appreciatehub.com/fonts/notosans/notosans-bold-webfont.woff);
          font-weight: 700;
          font-style: normal;
          font-display: swap;
        }
        @font-face {
          font-family: 'Noto Sans';
          src: url(https://oct.assets.appreciatehub.com/fonts/notosans/notosans-semibold-webfont.woff2), url(https://oct.assets.appreciatehub.com/fonts/notosans/notosans-semibold-webfont.woff);
          font-weight: 600;
          font-style: normal;
          font-display: swap;
        }
        @font-face {
          font-family: 'Noto Sans';
          src: url(https://oct.assets.appreciatehub.com/fonts/notosans/notosans-regular-webfont.woff2), url(https://oct.assets.appreciatehub.com/fonts/notosans/notosans-regular-webfont.woff);
          font-weight: 500;
          font-style: normal;
          font-display: swap;
        }
        @font-face {
          font-family: 'Noto Sans';
          src: url(https://oct.assets.appreciatehub.com/fonts/notosans/notosans-light-webfont.woff2), url(https://oct.assets.appreciatehub.com/fonts/notosans/notosans-light-webfont.woff);
          font-weight: 400;
          font-style: normal;
          font-display: swap;
        }
        @font-face {
          font-family: 'Noto Sans';
          src: url(https://oct.assets.appreciatehub.com/fonts/notosans/notosans-blackitalic-webfont.woff2), url(https://oct.assets.appreciatehub.com/fonts/notosans/notosans-blackitalic-webfont.woff);
          font-weight: 900;
          font-style: italic;
          font-display: swap;
        }
        @font-face {
          font-family: 'Noto Sans';
          src: url(https://oct.assets.appreciatehub.com/fonts/notosans/notosans-extrabolditalic-webfont.woff2), url(https://oct.assets.appreciatehub.com/fonts/notosans/notosans-extrabolditalic-webfont.woff);
          font-weight: 800;
          font-style: italic;
          font-display: swap;
        }
        @font-face {
          font-family: 'Noto Sans';
          src: url(https://oct.assets.appreciatehub.com/fonts/notosans/notosans-bolditalic-webfont.woff2), url(https://oct.assets.appreciatehub.com/fonts/notosans/notosans-bolditalic-webfont.woff);
          font-weight: 700;
          font-style: italic;
          font-display: swap;
        }
        @font-face {
          font-family: 'Noto Sans';
          src: url(https://oct.assets.appreciatehub.com/fonts/notosans/notosans-semibolditalic-webfont.woff2), url(https://oct.assets.appreciatehub.com/fonts/notosans/notosans-semibolditalic-webfont.woff);
          font-weight: 600;
          font-style: italic;
          font-display: swap;
        }
        @font-face {
          font-family: 'Noto Sans';
          src: url(https://oct.assets.appreciatehub.com/fonts/notosans/notosans-italic-webfont.woff2), url(https://oct.assets.appreciatehub.com/fonts/notosans/notosans-italic-webfont.woff);
          font-weight: 500;
          font-style: italic;
          font-display: swap;
        }
        @font-face {
          font-family: 'Noto Sans';
          src: url(https://oct.assets.appreciatehub.com/fonts/notosans/notosans-lightitalic-webfont.woff2), url(https://oct.assets.appreciatehub.com/fonts/notosans/notosans-lightitalic-webfont.woff);
          font-weight: 400;
          font-style: italic;
          font-display: swap;
        }
      `,
    },
    MuiAlert: {
      defaultProps: {
        icon: false,
      },
      styleOverrides: {
        root: {
          alignItems: 'center',
          fontSize: 14,
          padding: '2px 16px',
          lineHeight: '20px',
          '.MuiIconButton-sizeSmall': {
            fontSize: 14,
            '&:active': {
              borderRadius: '3px',
            },
          },
          '.MuiButton-text': {
            fontWeight: '800',
            lineHeight: '14px',
            minHeight: '24px',
            minWidth: 'auto',
            whiteSpace: 'nowrap',
          },
        },
        action: {
          padding: 0,
          alignItems: 'center',
        },
        standardError: {
          color: tannerSemantic.error.color,
          backgroundColor: tannerSemantic.error.background,
          '.MuiIconButton-sizeSmall, .MuiButton-text': {
            color: tannerSemantic.error.color,
            '&:hover': {
              backgroundColor: '#F0C2C2',
            },
            '&:active': {
              borderRadius: '3px;',
            },
          },
        },
        standardInfo: {
          color: tannerSemantic.info.color,
          backgroundColor: tannerSemantic.info.background,
          '.MuiIconButton-sizeSmall, .MuiButton-text': {
            color: tannerSemantic.info.color,
            '&:hover': {
              backgroundColor: '#BFD5F1',
            },
          },
        },
        standardSuccess: {
          color: tannerSemantic.success.color,
          backgroundColor: tannerSemantic.success.background,
          '.MuiIconButton-sizeSmall, .MuiButton-text': {
            color: tannerSemantic.success.color,
            '&:hover': {
              backgroundColor: '#ADDACB',
            },
          },
        },
        standardWarning: {
          color: tannerSemantic.warning.color,
          backgroundColor: tannerSemantic.warning.background,
          '.MuiIconButton-sizeSmall, .MuiButton-text': {
            color: tannerSemantic.warning.color,
            '&:hover': {
              backgroundColor: '#F8DFA0',
            },
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        inputRoot: {
          '& .prism-search': {
            minWidth: 100,
          },
        },
        listbox: {
          padding: 8,
          '& .MuiAutocomplete-option': {
            '&.Mui-focusVisible': {
              boxShadow: `0 0 0 2px ${tannerSemantic.info.color}`,
              outline: `3px solid ${tannerWhite}`,
              outlineOffset: 2,
              backgroundColor: tannerGray['100'],
            },
          },
        },
        endAdornment: {
          top: '50%',
          transform: 'translateY(-50%)',
        },
        tag: {
          margin: '2px 5px 2px 2px',
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: { fontSize: 20 },
        colorDefault: {
          backgroundColor: tannerGray['500'],
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        root: {
          alignItems: 'center',
        },
        badge: {
          borderRadius: 4,
          height: 18,
          fontSize: 12,
          position: 'relative',
          marginLeft: 8,
        },
        anchorOriginTopRightRectangular: {
          transform: 'initial',
        },
        anchorOriginBottomRightRectangular: {
          transform: 'initial',
        },
        anchorOriginBottomLeftRectangular: {
          transform: 'initial',
        },
        anchorOriginTopLeftRectangular: {
          transform: 'initial',
        },
        anchorOriginBottomRightCircular: {
          transform: 'initial',
        },
        anchorOriginTopLeftCircular: {
          transform: 'initial',
        },
        anchorOriginTopRightCircular: {
          transform: 'initial',
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          '&.MuiPickersDay-root.Mui-selected': {
            // Selected state styles
            '&:focus': {
              ':focus-visible': {
                backgroundColor: tannerPurple['600'],
                color: tannerWhite,
              },
            },
            '&:hover': {
              backgroundColor: tannerPurple['600'],
            },
          },
          '&.MuiPickersDay-dayWithMargin': {
            // Day with margin styles
            '&:focus': {
              ':focus-visible': {
                outline: `3px solid ${tannerSemantic.info.color}`,
                outlineOffset: 3,
                transition: 'outline-offset 150ms',
                backgroundColor: tannerGray['100'],
                zIndex: 1,
              },
            },
            '&.MuiPickersDay-today': {
              // Today's date styles
              border: '2px solid' + tannerWhite,
              outline: '1px solid' + tannerGray['300'],
              backgroundColor: tannerWhite,
              color: tannerBlack,
              '&:hover': {
                backgroundColor: tannerGray['100'],
              },
              '&:focus': {
                ':focus-visible': {
                  outline: '2px solid' + tannerWhite,
                  border: '1px solid' + tannerGray['300'],
                  boxShadow: '0 0 0 4px ' + tannerSemantic.info.color,
                  backgroundColor: tannerWhite,
                  color: tannerBlack,
                },
                ':not(:focus-visible)': {
                  backgroundColor: 'inherit',
                  color: 'inherit',
                },
              },
            },
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          '& .MuiPickersCalendarHeader-labelContainer': {
            overflow: 'visible',
          },
          '& .MuiPickersYear-yearButton.Mui-selected:focus-visible': {
            backgroundColor: tannerBlack,
            color: tannerWhite,
          },
          '& .MuiYearPicker-root': {
            '& .PrivatePickersYear-root': {
              '& .PrivatePickersYear-yearButton': {
                '&:focus, &:focus-visible': {
                  outline: `3px solid ${tannerSemantic.info.color}`,
                  outlineOffset: 3,
                },
              },
            },
          },
        },
      },
    },
    MuiButton: {
      defaultProps: {
        variant: 'contained',
        color: 'primary',
        disableElevation: true,
        disableFocusRipple: true,
      },
      variants: [
        {
          props: { color: 'white' },
          style: {
            color: '#000',
            backgroundColor: tannerWhite,
            '&.Mui-disabled': {
              color: '#000',
              backgroundColor: tannerWhite,
            },
            '&:hover, &:focus-visible': {
              backgroundColor: tannerGray['200'],
            },
          },
        },
        {
          props: { color: 'black' },
          style: {
            color: tannerWhite,
            backgroundColor: '#000',
            '&.Mui-disabled': {
              color: tannerWhite,
              backgroundColor: '#000',
            },
            '&:hover, &:focus-visible': {
              backgroundColor: tannerGray['700'],
            },
          },
        },
        {
          props: { color: 'blue' },
          style: {
            color: tannerWhite,
            backgroundColor: tannerBlue['500'],
            '&.Mui-disabled': {
              color: '#D9E7FA',
              backgroundColor: '#668CEC',
            },
            ':disabled': {
              backgroundColor: '#BCD5F8',
              color: `${tannerSemantic.info.background}`,
            },
            '&:hover, &:focus-visible': {
              backgroundColor: '#0049AB',
            },
          },
        },
        {
          props: { color: 'error' },
          style: {
            backgroundColor: `${tannerSemantic.error.color}`,
            ':disabled': {
              backgroundColor: '#EC8D90',
              color: `${tannerSemantic.error.background}`,
            },
            '&:hover, &:focus-visible': {
              backgroundColor: '#B00007',
            },
          },
        },
      ],
      styleOverrides: {
        root: {
          fill: 'currentColor',
          borderRadius: 3,
          fontWeight: 800,
          fontSize: 14,
          padding: '7px 16px',
          textTransform: 'initial',
          minHeight: '40px',
          lineHeight: '140%',
          border: '2px solid transparent',
          '&.Mui-disabled': {
            opacity: 0.4,
          },
          '&:focus-visible': {
            outline: `3px solid ${tannerSemantic.info.color}`,
            outlineOffset: 3,
            transition: 'outline-offset 150ms',
          },
        },
        iconSizeMedium: {
          '& > *:first-of-type': {
            fontSize: 16,
          },
        },
        textSizeSmall: {
          minWidth: 0,
          padding: '0 8px',
          '&:hover:after': {
            backgroundColor: 'currentColor',
            content: '""',
            opacity: 0.2,
            position: 'absolute',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            borderRadius: 3,
          },
        },
        text: {
          alignItems: 'center',
          borderRadius: 3,
          display: 'inline-flex',
          fontSize: 14,
          fontWeight: 600,
          lineHeight: '140%',
          padding: '0 6px',
          '& .MuiButton-startIcon': {
            marginLeft: 0,
          },
          '& .MuiButton-endIcon': {
            marginRight: 0,
          },
        },
        containedPrimary: {
          '&.Mui-disabled': {
            backgroundColor: 'inherit',
          },
        },
        containedSecondary: {
          '&:hover, &:focus-visible': {
            backgroundColor: tannerGray['300'],
          },
        },
        textPrimary: {
          color: tannerSemantic.info.color,
          '&:hover, &:focus-visible': {
            backgroundColor: tannerSemantic.info.background,
          },
          '&.Mui-disabled': {
            color: tannerSemantic.info.color,
          },
        },
        textSecondary: {
          color: tannerGray['600'],
          '&:hover, &:focus-visible': {
            color: tannerGray['900'],
            backgroundColor: tannerGray['100'],
          },
          '&$disabled': {
            color: tannerGray['400'],
          },
        },
        colorSecondary: {
          color: tannerWhite,
        },
      },
    },
    MuiButtonGroup: {
      defaultProps: {
        variant: 'contained',
        color: 'primary',
        disableElevation: true,
        disableFocusRipple: true,
      },
      styleOverrides: {
        groupedContainedPrimary: {
          '&:last-child': {
            paddingLeft: 8,
            paddingRight: 8,
            minWidth: 34,
            margin: '2px 2px 2px 0',
          },
          '&:not(:last-child)': {
            borderColor: tannerWhite,
          },
        },
        groupedContainedSecondary: {
          '&:last-child': {
            paddingLeft: 8,
            paddingRight: 8,
            minWidth: 34,
            margin: '2px 2px 2px 0',
          },
          '&:not(:last-child)': {
            borderColor: tannerWhite,
          },
        },
      },
    },
    MuiBreadcrumbs: {
      styleOverrides: {
        root: {
          li: {
            a: {
              color: tannerGray[700],
              svg: {
                verticalAlign: 'sub',
                marginRight: '3px',
                color: tannerGray[600],
                height: '17px',
              },
              '&:hover': {
                color: tannerBlack,
                svg: {
                  color: tannerBlack,
                },
              },
              '&:active': {
                color: tannerGray[800],
                svg: {
                  color: tannerGray[800],
                },
              },
            },
            p: {
              color: tannerGray[700],
            },
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow:
            '0px 0px 2px rgba(0, 0, 0, 0.14), 0px 2px 2px rgba(0, 0, 0, 0.17), 0px 1px 3px rgba(0, 0, 0, 0.2)',
        },
      },
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          padding: '8px 16px 16px 16px',
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          '&:last-child': {
            paddingBottom: 16,
          },
        },
      },
    },
    MuiCheckbox: {
      defaultProps: {
        color: 'primary',
      },
      styleOverrides: {
        root: {
          fontSize: 18,
        },
        colorSecondary: {
          color: tannerGray['700'],
          '&.Mui-checked': {
            color: tannerGray['700'],
          },
        },
        colorPrimary: {
          color: tannerGray['700'],
          '&.Mui-checked': {
            color: '#0066ED',
          },
        },
      },
    },
    MuiChip: {
      defaultProps: {
        variant: 'outlined',
        color: 'primary',
      },
      styleOverrides: {
        root: {
          marginRight: '8px',
          '&.Mui-focusVisible': {
            '&.MuiChip-outlined': {
              outline: `3px solid ${tannerSemantic.info.color}`,
              outlineOffset: '3px',
              backgroundColor: 'transparent',
            },
          },
        },
        colorPrimary: {
          transition: 'color 300ms, border-color 300ms',
          color: tannerBlack,
          borderColor: tannerGray['300'],
          '&.MuiChip-clickable': {
            '&.MuiChip-outlined': {
              '&:hover': {
                transition: 'border-color 300ms, box-shadow 300ms',
                boxShadow: `0 0 0 2px ${tannerGray['200']}`,
                borderColor: tannerGray['600'],
              },
            },
          },
          '& .MuiChip-deleteIcon': {
            color: tannerGray[600],
            '&:hover,&:active': {
              transition: 'color 300ms',
              color: tannerBlack,
            },
          },
        },
        colorError: {
          '& .MuiChip-deleteIcon': {
            color: tannerSemantic.error.color,
            '&:hover,&:active': {
              transition: 'color 300ms',
              color: '#820000',
            },
          },
          '&.MuiChip-clickable': {
            '&.MuiChip-outlined': {
              '&:hover': {
                boxShadow: `0 0 0 2px #FBC4C7`,
              },
            },
          },
          '&.MuiChip-filledError': {
            '& .MuiChip-deleteIcon': {
              '&:hover,&:active': {
                transition: 'color 300ms',
                color: '#FBC4C7',
              },
            },
            '&.Mui-focusVisible': {
              outline: `3px solid ${tannerSemantic.info.color}`,
              outlineOffset: '3px',
              backgroundColor: '#D70E16',
            },
            '&.MuiChip-clickable:hover': {
              backgroundColor: '#B00007',
            },
          },
        },
        colorWarning: {
          color: tannerSemantic.warning.color,
          borderColor: tannerSemantic.warning.color,
          '& .MuiChip-deleteIcon': {
            color: tannerSemantic.warning.color,
            '&:hover,&:active': {
              transition: 'color 300ms',
              color: '#672600',
            },
          },
          '&:hover': {
            boxShadow: `0 0 0 2px #FCE0BC`,
          },

          '&.MuiChip-filledWarning': {
            color: tannerWhite,
            backgroundColor: tannerSemantic.warning.color,
            '& .MuiChip-deleteIcon': {
              '&:hover,&:active': {
                transition: 'color 300ms',
                color: '#FCE0BC',
              },
            },
            '&:hover': {
              backgroundColor: '#903500',
            },
            '&:focus-visible, &.Mui-focusVisible': {
              backgroundColor: tannerSemantic.warning.color,
              outline: `3px solid ${tannerSemantic.info.color}`,
              outlineOffset: '3px',
            },
          },
        },
        colorInfo: {
          color: tannerSemantic.info.color,
          borderColor: tannerSemantic.info.color,
          '& .MuiChip-deleteIcon': {
            color: tannerSemantic.info.color,
            '&:hover,&:active': {
              transition: 'color 300ms',
              color: '#003686',
            },
          },
          '&.MuiChip-clickable': {
            '&.MuiChip-outlined': {
              '&:hover': {
                boxShadow: `0 0 0 2px #BCD5F8`,
              },
            },
          },
          '&.MuiChip-filledInfo': {
            color: tannerWhite,
            backgroundColor: tannerSemantic.info.color,
            '&.MuiChip-clickable': {
              '&:hover': {
                backgroundColor: '#0049AB',
              },
              '&:focus-visible, &.Mui-focusVisible': {
                backgroundColor: tannerSemantic.info.color,
                outline: `3px solid ${tannerSemantic.info.color}`,
                outlineOffset: '3px',
              },
            },
            '& .MuiChip-deleteIcon': {
              '&:hover,&:active': {
                transition: 'color 300ms',
                color: '#BCD5F8',
              },
            },
          },
        },
        colorSuccess: {
          '& .MuiChip-deleteIcon': {
            color: tannerSemantic.success.color,
            '&:hover,&:active': {
              transition: 'color 300ms',
              color: '#003512',
            },
          },
          '&.MuiChip-clickable': {
            '&.MuiChip-outlined': {
              '&:hover': {
                boxShadow: `0 0 0 2px #A2DDBE`,
              },
            },
          },
          '&.MuiChip-filledSuccess': {
            '& .MuiChip-deleteIcon': {
              '&:hover,&:active': {
                transition: 'color 300ms',
                color: '#A2DDBE',
              },
            },
            '&.MuiChip-clickable': {
              '&:hover': {
                backgroundColor: '#00511B',
              },
              '&.Mui-focusVisible': {
                backgroundColor: '#07702A',
                outline: `3px solid ${tannerSemantic.info.color}`,
                outlineOffset: '3px',
              },
            },
          },
        },
        outlinedSecondary: {
          '& .MuiChip-deleteIcon': {
            width: 12,
            height: 12,
            marginRight: 8,
            color: tannerGray['200'],
          },
        },
        filled: {
          '& .MuiChip-deleteIcon': {
            width: 12,
            height: 12,
            marginRight: 9,
            color: tannerWhite,
          },
          '&:hover': {
            boxShadow: `0 0 0 0`,
          },
          '&.MuiChip-deletable': {
            '& .MuiChip-label': {
              paddingRight: 11,
              paddingLeft: 12,
            },
          },
        },
        filledPrimary: {
          transition: 'all 300ms',
          color: tannerWhite,
          backgroundColor: tannerSemantic.info.color,
          '&.MuiChip-clickable': {
            '&:hover': {
              backgroundColor: '#0049AB',
            },
            '&.Mui-focusVisible': {
              transition: 'background-color 300ms',
              backgroundColor: tannerSemantic.info.color,
              outline: `3px solid ${tannerSemantic.info.color}`,
              outlineOffset: '3px',
            },
          },
          '& .MuiChip-deleteIcon': {
            color: tannerWhite,
            '&:hover, &:active': {
              transition: 'color 300ms',
              color: '#BCD5F8',
            },
          },
        },
        filledSecondary: {
          '& .MuiChip-deleteIcon': {
            width: 12,
            height: 12,
            marginRight: 8,
            color: tannerGray['400'],
            '&:hover': {
              transition: 'color 300ms',
              color: tannerBlack,
            },
          },
        },
        deleteIcon: {
          width: 12,
          height: 12,
          marginRight: 8,
          color: tannerGray['400'],
        },
        deleteIconColorPrimary: {
          '&:hover': {
            transition: 'color 300ms',
            color: tannerBlack,
          },
        },
        sizeMedium: {
          fontSize: '14px',
        },
        sizeSmall: {
          fontSize: '12px',
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          boxShadow:
            '0px 4px 14px rgba(0, 0, 0, 0.2), 0px 3px 14px rgba(0, 0, 0, 0.12), 0px 8px 10px rgba(0, 0, 0, 0.14)',
        },
        paperWidthSm: {
          maxWidth: 684,
        },
        paperFullScreen: {
          maxWidth: 'none',
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          justifyContent: 'center',
          padding: '14px 24px',
          '&.shadow': {
            boxShadow: dialogShadow,
          },
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: '0px 24px',
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          position: 'relative',
          zIndex: 1,
          '&.shadow': {
            boxShadow: dialogShadow,
          },
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          minWidth: 260,
        },
      },
    },
    MuiIconButton: {
      defaultProps: {
        disableFocusRipple: true,
      },
      variants: [
        {
          props: { edge: 'end' },
          style: {
            marginRight: 0,
          },
        },
        {
          props: { size: 'small' },
          style: {
            height: 32,
            width: 32,
          },
        },
        {
          props: { color: 'default' },
          style: {
            color: '#000',
            '&.Mui-disabled': {
              color: tannerGray['400'],
            },
            '&:hover, &:focus-visible': {
              backgroundColor: tannerGray['100'],
            },
          },
        },
        {
          props: { color: 'white' },
          style: {
            color: '#000',
            backgroundColor: tannerWhite,
            '&.Mui-disabled': {
              color: '#000',
              backgroundColor: tannerGray['600'],
            },
            '&:hover, &:focus-visible': {
              backgroundColor: tannerGray['200'],
            },
          },
        },
        {
          props: { color: 'black' },
          style: {
            color: tannerWhite,
            backgroundColor: '#000',
            '&.Mui-disabled': {
              color: tannerWhite,
              backgroundColor: tannerGray['400'],
            },
            '&:hover, &:focus-visible': {
              color: tannerWhite,
              backgroundColor: tannerGray['800'],
            },
          },
        },
      ],
      styleOverrides: {
        root: {
          color: '#000',
          borderRadius: 3,
          fontSize: 'inherit',
          height: 40,
          width: 40,
          transition:
            'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
          '&:focus': {
            ':not(:focus-visible)': {
              outline: 'none',
              border: 'none',
            },
          },
          '&:focus-visible': {
            outline: `3px solid ${tannerSemantic.info.color}`,
            outlineOffset: 3,
            transition: 'outline-offset 150ms',
          },
          '&:hover, &:focus-visible': {
            backgroundColor: tannerGray['100'],
            color: tannerBlack,
          },
        },
      },
    },
    MuiFab: {
      defaultProps: {
        variant: 'extended',
        disableFocusRipple: true,
        disableRipple: true,
        disableTouchRipple: true,
      },
      variants: [
        {
          props: { color: 'blue' },
          style: {
            color: tannerBlue['500'],
            '&.Mui-disabled': {
              boxShadow: 'none',
              color: `${tannerBlue['500']}99`,
              'span .MuiBox-root': {
                color: tannerBlue['500'],
              },
            },
          },
        },
        {
          props: { color: 'secondary' },
          style: {
            color: tannerBlack,
            '&.Mui-disabled': {
              boxShadow: 'none',
              color: `${tannerBlack}99`,
              'span .MuiBox-root': {
                color: tannerBlack,
              },
            },
          },
        },
        {
          props: { color: 'error' },
          style: {
            color: tannerSemantic.error.color,
            '&.Mui-disabled': {
              boxShadow: 'none',
              color: `${tannerSemantic.error.color}99`,
              'span .MuiBox-root': {
                color: tannerSemantic.error.color,
              },
            },
          },
        },
        {
          props: { variant: 'circular' },
          style: {
            borderRadius: '50%',
            height: 40,
            width: 40,
            padding: 0,
          },
        },
      ],
      styleOverrides: {
        root: {
          borderRadius: 100,
          background: tannerGray['50'],
          boxShadow:
            '0px 0px 2px 0px rgba(0, 0, 0, 0.14), 0px 2px 2px 0px rgba(0, 0, 0, 0.17), 0px 1px 3px 0px rgba(0, 0, 0, 0.20)',
          height: '40px',
          lineHeight: '140%',
          color: tannerPurple['500'],
          fontSize: 14,
          padding: '8px 16px 8px 16px',
          fontWeight: 700,
          textTransform: 'none',
          '&:hover': {
            background: tannerGray['200'],
            boxShadow:
              '0px 2px 4px 0px rgba(0, 0, 0, 0.14), 0px 4px 5px 0px rgba(0, 0, 0, 0.12), 0px 1px 10px 0px rgba(0, 0, 0, 0.20)',
          },
          '&:active': {
            background: tannerGray['300'],
            boxShadow:
              '0px 0px 2px 0px rgba(0, 0, 0, 0.14), 0px 2px 2px 0px rgba(0, 0, 0, 0.17), 0px 1px 3px 0px rgba(0, 0, 0, 0.20)',
          },
          '&.Mui-disabled': {
            boxShadow: 'none',
            background: tannerGray['100'],
            color: `${tannerPurple['500']}99`,
            'span .MuiBox-root': {
              color: tannerPurple['500'],
            },
          },
          '&:focus-visible': {
            borderRadius: '37px',
            outline: `3px solid ${tannerSemantic.info.color}`,
            outlineOffset: 3,
            transition: 'outline-offset 150ms',
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: '#000',
          fontSize: 14,
          fontWeight: 800,
          lineHeight: '130%',
          '&.Mui-disabled': {
            color: tannerGray['400'],
          },
        },
      },
    },
    MuiGrid: {
      defaultProps: {
        spacing: 3,
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          backgroundColor: tannerWhite,
          '&:hover': {
            borderColor: '#000',
          },
        },
        input: {
          fontSize: 14,
        },
      },
    },
    MuiInput: {
      defaultProps: {
        disableUnderline: true,
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'filled',
      },
      variants: [
        {
          props: { variant: 'filled' },
          style: {
            '& .MuiCharacterCount': {
              flex: 'none',
              paddingLeft: '8px',
            },
            '&.MuiTextField-multiline .MuiInputLabel-root': {
              display: 'block',
              width: '100%',
              maxWidth: '100%',
              padding: '20px 16px 0px',
              textOverflow: 'initial',
              whiteSpace: 'initial',
              overflow: 'initial',
              transform: 'translate(0, 0) scale(1)',
              '&.MuiInputLabel-shrink': {
                display: '-webkit-box',
                width: '123.25%',
                maxWidth: '123.25%',
                transform: 'translate(4px, -4px) scale(0.8)',
                boxOrient: 'vertical',
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
              },
            },
            '&.MuiTextField-multiline.MuiTextField-multiline-clamp-': {
              '&1': {
                '.MuiInputLabel-shrink': {
                  lineClamp: '1',
                  WebkitLineClamp: '1',
                },
              },
              '&2': {
                '.MuiInputLabel-shrink': {
                  lineClamp: '2',
                  WebkitLineClamp: '2',
                },
                '.MuiFilledInput-root': {
                  paddingTop: '41px',
                },
              },
              '&3': {
                '.MuiInputLabel-shrink': {
                  lineClamp: '3',
                  WebkitLineClamp: '3',
                },
                '.MuiFilledInput-root': {
                  paddingTop: '57px',
                },
              },
            },
            '& input[readonly]': {
              borderColor: tannerGray['100'],
            },
            '&.MuiTextField-readonly': {
              '& .MuiInputBase-root': {
                borderWidth: 1,
                pointerEvents: 'none',
                borderStyle: 'solid',
                borderColor: tannerGray['200'],
                overflow: 'hidden',
                borderRadius: 3,
                transition: 'border-color .2s, box-shadow .2s',
                ':hover': {
                  borderColor: tannerGray['200'],
                  '&.Mui-disabled': {
                    borderColor: tannerGray['200'],
                  },
                },
                '&.Mui-focused': {
                  backgroundColor: tannerWhite,
                  borderColor: tannerGray['200'],
                  boxShadow: `none`,
                },
              },
              '.MuiIconButton-root': {
                color: tannerGray['400'],
                fontSize: '12px',
                pointerEvents: 'visible',
                cursor: 'auto',
                height: '20px',
                '&:hover': {
                  backgroundColor: tannerWhite,
                },
              },
            },
            '& .MuiInputBase-root': {
              borderWidth: 1,
              borderStyle: 'solid',
              borderColor: tannerGray['500'],
              overflow: 'hidden',
              borderRadius: 3,
              transition: 'border-color .2s, box-shadow .2s',
              minHeight: '56px',
              '&:hover': {
                borderColor: '#000',
                '&.Mui-disabled': {
                  borderColor: tannerGray['300'],
                },
              },
              '& input[type="number"], & input[type="search"]': {
                WebkitAppearance: 'textfield',
                MozAppearance: 'textfield',
                appearance: 'textfield',
              },
              '& input[type=number]::-webkit-inner-spin-button, & input[type=number]::-webkit-outer-spin-button':
                {
                  WebkitAppearance: 'none',
                },
              '& input[type="search"]::-webkit-search-decoration, & input[type="search"]::-webkit-search-cancel-button, & input[type="search"]::-webkit-search-results-button, & input[type="search"]::-webkit-search-results-decoration':
                {
                  WebkitAppearance: 'none',
                },
              '&.Mui-focused': {
                backgroundColor: tannerWhite,
                borderColor: '#000',
                boxShadow: `0px 0px 0px 2px ${tannerGray['900']}33`,
              },
              '&.Mui-error': {
                boxShadow: `${tannerSemantic.error.background} 0 0 0 2px`,
                borderColor: tannerSemantic.error.color,
              },
            },
            '& .MuiInputBase-multiline': {
              minHeight: '56px',
              alignItems: 'normal',
              '.MuiIconButton-root': {
                alignItems: 'start',
                marginTop: '-12px',
              },
            },
          },
        },
        {
          props: { variant: 'outlined' },
          style: {
            '& .MuiInputBase-root': {
              padding: '10px',
              minHeight: '56px',
              '&.MuiInputBase-sizeSmall': {
                minHeight: '40px',
              },
              '&.Mui-focused fieldset': {
                borderColor: '#000',
                borderWidth: 1,
                boxShadow: `0px 0px 0px 2px ${tannerGray['900']}33`,
              },
              '&.Mui-error fieldset': {
                boxShadow: `${tannerSemantic.error.background} 0 0 0 2px`,
              },
            },
            '& fieldset': {
              borderWidth: 1,
              borderStyle: 'solid',
              borderColor: tannerGray['500'],
              overflow: 'hidden',
              borderRadius: 3,
              transition: 'border-color .2s, box-shadow .2s',
              '&.Mui-error': {
                boxShadow: `${tannerSemantic.error.background} 0 0 0 2px`,
                borderColor: tannerSemantic.error.color,
              },
            },
            '& fieldset legend': {
              fontSize: '0.65em',
            },
          },
        },
      ],
    },
    MuiFilledInput: {
      defaultProps: { disableUnderline: true },
      styleOverrides: {
        root: {
          backgroundColor: tannerWhite,
          paddingRight: 8,
          '&:hover': {
            backgroundColor: tannerWhite,
            borderColor: '#000',
          },
          '&.Mui-disabled': {
            backgroundColor: tannerGray['50'],
            color: tannerGray['400'],
            WebkitTextFillColor: tannerGray['400'],
          },
        },
        adornedStart: {
          paddingLeft: 16,
        },
        inputAdornedStart: {
          paddingLeft: 0,
        },
        inputAdornedEnd: {
          paddingRight: 16,
        },
        input: {
          paddingLeft: 16,
        },
        inputMultiline: {
          paddingLeft: 4,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: tannerGray['600'],
          fontWeight: 500,
          '&.Mui-error,&.Mui-error.Mui-focused': {
            color: tannerSemantic.error.color,
          },
          '&.Mui-focused': {
            color: tannerGray['600'],
          },
          '&.Mui-disabled': {
            color: tannerGray['400'],
          },
        },
        filled: {
          maxWidth: '65%',
          color: tannerGray['600'],
          fontWeight: 500,
          transform: 'translate(17px, 20px) scale(1)',
          '&.MuiInputLabel-shrink': {
            transform: 'translate(17px, 12px) scale(.8)',
            maxWidth: '100%',
          },
          '&.MuiInputLabel-sizeSmall': {
            transform: 'translate(17px, 16px) scale(1)',
            '&.MuiInputLabel-shrink': {
              transform: 'translate(17px, 8px) scale(.8)',
              maxWidth: '100%',
            },
          },
        },
        outlined: {
          color: tannerGray['600'],
          fontWeight: 500,
          transform: 'translate(17px, 20px) scale(1)',
          '&.MuiInputLabel-shrink': {
            transform: 'translate(17px, -6px) scale(.8)',
            maxWidth: '100%',
          },
          '&.MuiInputLabel-sizeSmall': {
            transform: 'translate(17px, 11px) scale(1)',
            '&.MuiInputLabel-shrink': {
              transform: 'translate(17px, -6px) scale(.8)',
              maxWidth: '100%',
            },
          },
        },
      },
    },
    MuiLink: {
      defaultProps: {
        underline: 'none',
      },
      styleOverrides: {
        root: {
          fontSize: '14px',
          lineHeight: '20px',
          color: tannerSemantic.info.color,
          '&.MuiTypography-colorPrimary': {
            color: '#0066ED',
          },
          '&:hover': {
            color: tannerSemantic.info.color,
            textDecoration: 'underline',
          },
          '&:active': {
            color: tannerSemantic.info.color,
          },
          '&:Mui-focusVisible': {
            outline: '2px solid' + tannerSemantic.info.color,
            border: `3px solid ${tannerWhite}`,
          },
          '&[disabled]': {
            color: tannerSemantic.info.color,
            cursor: 'default',
            opacity: 0.3,
            pointerEvents: 'none',
          },
          '& svg': {
            fill: 'currentColor',
            height: '16px',
            width: '16px',
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: 14,
          '& .MuiListItemText-root': {
            paddingLeft: 8,
          },
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          // forcing 8px padding due to MUI padding-right: 0 scrollbar issue
          padding: '8px !important',
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          marginTop: 4,
        },
      },
    },
    MuiRadio: {
      defaultProps: {
        color: 'primary',
      },
      styleOverrides: {
        colorPrimary: {
          color: '#0066ED',
          '&.Mui-checked': {
            color: '#0066ED',
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        filled: {
          height: '100%',
          boxSizing: 'border-box',
        },
      },
    },
    MuiSwitch: {
      defaultProps: {
        color: 'primary',
      },
      styleOverrides: {
        root: {
          width: '36px',
          height: '24px',
          padding: '0',
          margin: '4px',
        },
        colorPrimary: {
          '&.Mui-checked': {
            color: '#0060E0',
            '& + .MuiSwitch-track': {
              backgroundColor: '#0060E0',
            },
          },
          '&.Mui-disabled + .MuiSwitch-track': {
            backgroundColor: tannerGray['500'],
            opacity: 0.3,
          },
          '&.Mui-disabled.Mui-checked + .MuiSwitch-track': {
            opacity: 0.3,
            backgroundColor: tannerBlue['500'],
          },
        },
        switchBase: {
          color: tannerGray['200'],
          padding: 2,
          '&.Mui-disabled': {
            color: '#F8F8F8',
          },
          '&.Mui-disabled div': {
            'box-shadow': 'none',
            background: tannerGray[100],
          },
          '&.Mui-disabled.Mui-checked div': {
            'box-shadow': 'none',
            background: tannerWhite,
          },
          '&.Mui-disabled.Mui-checked': {
            opacity: 1,
            color: '#B2D1F9',
          },
          '&:hover': {
            backgroundColor: 'transparent',
            transform: 'translateX(2px)',
          },
          '&:hover.Mui-checked': {
            transform: 'translateX(8px)',
          },
          '&.Mui-checked': {
            transform: 'translateX(10px)',
            '& + .MuiSwitch-track': {
              opacity: 1,
            },
          },
          '&.Mui-checked svg': {
            color: tannerBlue[500],
          },
          '&.Mui-disabled.Mui-checked svg': {
            opacity: 0.3,
          },
        },
        track: {
          borderRadius: 28 / 2,
          opacity: 1,
          transition: 'background-color 200ms',
          backgroundColor: tannerGray['300'],
        },
      },
    },
    MuiSvgIcon: {
      defaultProps: { fontSize: 'inherit', color: 'inherit' },
      styleOverrides: {
        root: {
          fontSize: 'inherit',
          color: 'inherit',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          color: '#000',
          fontSize: 14,
          fontWeight: 500,
          minWidth: 50,
          textTransform: 'none',
          [breakpoints.up('sm')]: { minWidth: 50 },
          '&:focus-visible, &.Mui-focusVisible': {
            backgroundColor: tannerGray['100'],
            outline: `3px solid ${tannerSemantic.info.color}`,
            outlineOffset: '-3px',
            borderRadius: '3px',
          },
          '&:hover': {
            backgroundColor: tannerGray[50],
          },
          '&.Mui-selected': {
            color: tannerBlack,
          },
          '&:active': {
            backgroundColor: tannerGray[100],
          },
        },
        textColorInherit: {
          opacity: 1,
        },
      },
    },
    MuiTabs: {
      defaultProps: {
        indicatorColor: 'primary',
      },
      styleOverrides: {
        root: {
          borderBottom: `1px solid ${tannerGray['200']}`,
        },
        scrollButtons: {
          '&.Mui-disabled': {
            opacity: 0.3,
          },
        },
        indicator: {
          height: 4,
        },
      },
    },
    MuiTooltip: {
      defaultProps: {
        arrow: true,
      },
      styleOverrides: {
        arrow: {
          color: tannerGray['800'],
        },
        tooltip: {
          backgroundColor: tannerGray['800'],
          color: tannerWhite,
          fontSize: 12,
          padding: '8px 12px',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: { wordBreak: 'break-word' },
        paragraph: {
          fontSize: 14,
          lineHeight: '150%',
          color: tannerGray['900'],
        },
        body1: { fontSize: 14, lineHeight: '150%', color: tannerGray['900'] },
        body2: { fontSize: 12, lineHeight: '150%', color: tannerGray['900'] },
        h1: { fontSize: 28, fontWeight: 800, lineHeight: '150%' },
        h2: { fontSize: 20, fontWeight: 800, lineHeight: '150%' },
        h3: { fontSize: 16, fontWeight: 800, lineHeight: '150%' },
        h4: {
          fontSize: 14,
          fontWeight: 800,
          lineHeight: '150%',
        },
        h5: { fontSize: 16, fontWeight: 'bold', lineHeight: '150%' },
        h6: { fontSize: 16, fontWeight: 'normal', lineHeight: '150%' },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          borderRadius: 5,
        },
        bar: {
          borderRadius: 5,
        },
      },
    },
  },
};

export default themeOptions;
