import { ListItem, ListItemText } from '@mui/material';
import React, { FC } from 'react';
import VerticalTab from '../VerticalTab';
import {
  AccessCodeStyle,
  ListItemStyle,
  SecondaryListItemText,
} from './styles';
import { useTranslation } from 'react-i18next';
import { tannerGray } from '../ThemeProvider/colors';
import { MobileMultipleBanksProps } from './types';

const MobileMultipleBanks: FC<MobileMultipleBanksProps> = ({
  banks,
  codes,
  handleNavigation,
  identifier,
}) => {
  const { t } = useTranslation();

  return (
    <VerticalTab
      data-testid="multiple-banks-menu-button"
      label={t('prism-header:store')}
      key={identifier + '-multiple-banks'}
    >
      {banks.map(({ url, primary, secondary }) => (
        <ListItem
          data-testid={`multiple-banks-menu-${primary}`}
          key={primary}
          className="fs-unmask"
          component="a"
          sx={ListItemStyle}
          href={url}
          onClick={handleNavigation(url!)}
        >
          <ListItemText primary={primary} />
          <SecondaryListItemText primary={secondary} />
        </ListItem>
      ))}
      {codes && codes.length > 0 && (
        <VerticalTab
          data-testid="access-codes"
          label={t('prism-header:access-codes')}
          key={identifier + '-access-codes'}
        >
          {codes.map(({ url, primary, secondary }) => (
            <ListItem
              data-testid={`multiple-banks-menu-${primary}`}
              key={primary}
              className="fs-unmask"
              component="a"
              sx={AccessCodeStyle}
              href={url}
              onClick={handleNavigation(url!)}
            >
              <ListItemText primary={primary} />
              <SecondaryListItemText
                primary={secondary}
                primaryTypographyProps={{
                  fontSize: '12px',
                  color: tannerGray['600'],
                }}
              />
            </ListItem>
          ))}
        </VerticalTab>
      )}
    </VerticalTab>
  );
};

export default MobileMultipleBanks;
