import React, { useContext, useEffect, useState } from 'react';
import LogoLink from './LogoLink';
import {
  ContainerCell,
  HeaderCell,
  HeaderInnerRow,
  HeaderRow,
  SearchExpandHeaderCell,
} from './styles';
import { VariableHeaderProps } from './types';
import StoreButton from './StoreButton';
import ProfileMenu from './ProfileMenu';
import useLayoutLinks from './hooks/useLayoutLinks';
import TabRow from './TabRow';
import NotificationBell from './NotificationBell';
import SearchExpand from './SearchExpand';
import LayoutContext from '../context/Layout';
import useTranslations from './hooks/useTranslations';
import { useGetPointName } from './hooks/useGetPointName';
import SecondaryButtons from './SecondaryButtons';

const VariableHeader: React.FC<VariableHeaderProps> = ({
  headerView,
  multipleBanksObj,
  logoutUrl,
  routerObj: { routerPush, routerRoutes, routerPath, testRoute },
}) => {
  const [searchOpen, setSearchOpen] = useState(false);
  const [profileMenuEl, setProfileMenuEl] = useState<HTMLButtonElement | null>(
    null,
  );
  const [currentIdentityQuery, layoutQuery, localeQuery] =
    useContext(LayoutContext);
  const { lang } = useTranslations(
    currentIdentityQuery?.data?.currentIdentity,
    localeQuery?.data?.customerCoreLocaleMapsByCustomer?.nodes,
  );
  const links = useLayoutLinks(layoutQuery?.data);
  const currentIdentity = currentIdentityQuery?.data?.currentIdentity;
  const layoutLoading = layoutQuery?.loading;
  const currentIdentityLoading = currentIdentityQuery?.loading;
  const { data: vibePointInfo, loading: vibePointLoading } = useGetPointName(
    currentIdentity?.customer?.customerUuid,
    currentIdentity?.identityUuid,
    lang,
  );
  const vibeLoading = vibePointLoading || !Boolean(vibePointInfo);
  const specialPoint =
    vibePointInfo?.vibeGetDerivedVibeDetails?.attributes?.text
      ?.pointsNamePlural;

  const handleNavigation = (url: string) => (e: React.MouseEvent) => {
    if (routerPush && routerRoutes.some(testRoute(url))) {
      e.preventDefault();
      routerPush(url);
    }
    setProfileMenuEl(null);
  };

  useEffect(() => {
    if (searchOpen) {
      const focusElement = document.getElementById('search-input');
      if (focusElement) {
        focusElement.focus();
      }
    }
  }, [searchOpen]);

  const newTabProps = {
    target: '_blank',
    rel: 'noopener noreferrer',
  };

  return (
    <>
      <HeaderRow slim={!headerView}>
        <HeaderInnerRow>
          <LogoLink handleNavigation={handleNavigation} />
          <HeaderCell>
            <SecondaryButtons
              links={links.secondaryLinks}
              handleNavigation={handleNavigation}
              layoutLoading={layoutLoading}
              newTabProps={newTabProps}
              headerView={headerView}
            />
            <StoreButton
              multipleBanksObj={{
                ...multipleBanksObj,
                vibesPointName: specialPoint,
              }}
              handleNavigation={handleNavigation}
              vibePointLoading={vibeLoading}
            />
            <ProfileMenu
              links={links.menuLinks}
              logoutUrl={logoutUrl}
              handleNavigation={handleNavigation}
              profileMenuEl={profileMenuEl}
              setProfileMenuEl={setProfileMenuEl}
              currentIdentityLoading={currentIdentityLoading}
            />
          </HeaderCell>
        </HeaderInnerRow>
      </HeaderRow>
      {headerView && (
        <HeaderRow secondary>
          <HeaderInnerRow>
            <ContainerCell searchOpen={searchOpen}>
              <LogoLink
                handleNavigation={handleNavigation}
                isCultureCloud={true}
              />
              <TabRow
                handleNavigation={handleNavigation}
                vibePointLoading={vibeLoading}
                links={links}
                newTabProps={newTabProps}
                routerPath={routerPath}
              />
            </ContainerCell>
            <SearchExpandHeaderCell searchOpen={searchOpen} snipBorder={false}>
              <SearchExpand
                searchOpen={searchOpen}
                handleSearchOpen={setSearchOpen}
                routerObj={{ routerPush, routerRoutes, testRoute }}
              />
            </SearchExpandHeaderCell>
            <HeaderCell>
              <NotificationBell
                color="secondary"
                data-testid="notification-icon"
              />
            </HeaderCell>
          </HeaderInnerRow>
        </HeaderRow>
      )}
    </>
  );
};

export default VariableHeader;
