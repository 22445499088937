import ListItem from '../ListItem';
import ListItemText from '../ListItemText';
import React, { FC, Fragment, useContext } from 'react';
import { ListItemStyle, MenuAvatarText } from './styles';
import { useTranslation } from 'react-i18next';
import { MobileListItemsProps } from './types';
import {
  MenuLinks,
  PrimaryLinks,
  SecondaryLinks,
} from './hooks/useLayoutLinks';
import Avatar from '../Avatar';
import MobileMultipleBanks from './MobileMultipleBanks';
import LayoutContext from '../context/Layout';

const MobileListItems: FC<MobileListItemsProps> = ({
  links,
  handleNavigation,
  avatarSrc,
  fullName,
  multipleBanksObj,
  identifier,
}) => {
  const { t } = useTranslation();
  const [__, layoutQuery, _] = useContext(LayoutContext);
  const layout = layoutQuery?.data;
  const hasRedeemPermission =
    layout?.hasRedeemAwardsPermission?.hasRedeemAwardsPermission;

  const newTabProps = {
    target: '_blank',
    rel: 'noopener noreferrer',
  };

  const getMultipleBanks = () => {
    if (multipleBanksObj && hasRedeemPermission) {
      if (multipleBanksObj.hasMultipleBank) {
        return (
          <MobileMultipleBanks
            banks={multipleBanksObj.multipleBanks}
            codes={multipleBanksObj.accessCodes}
            handleNavigation={handleNavigation}
            identifier={identifier}
          />
        );
      } else {
        return (
          <ListItem
            data-testid="store-link"
            className="fs-unmask"
            component="a"
            key="store"
            sx={ListItemStyle}
            href={multipleBanksObj?.bankUrl}
            onClick={handleNavigation(multipleBanksObj.bankUrl)}
          >
            <ListItemText aria-label="Store. Shop Now">
              {t('prism-header:store')}
            </ListItemText>
          </ListItem>
        );
      }
    } else {
      return null;
    }
  };

  return (
    <>
      {links.map(({ id, translationString, url }) => (
        <Fragment key={identifier + id}>
          <ListItem
            data-testid={`${translationString}-link`}
            key={identifier + id + '-link'}
            className="fs-unmask"
            sx={ListItemStyle}
            component="a"
            onClick={handleNavigation(url)}
            href={url}
            {...((id === SecondaryLinks.Admin ||
              id === PrimaryLinks.Wellbeing) &&
              newTabProps)}
          >
            {id === MenuLinks.Profile ? (
              <>
                <Avatar src={avatarSrc} size="sm" />
                <MenuAvatarText>{fullName}</MenuAvatarText>
              </>
            ) : (
              <ListItemText>
                {t(`prism-header:${translationString}`)}
              </ListItemText>
            )}
          </ListItem>
          {id === MenuLinks.Profile && getMultipleBanks()}
        </Fragment>
      ))}
    </>
  );
};

export default MobileListItems;
