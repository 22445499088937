import React from 'react';
import { StepIconProps } from '@mui/material/StepIcon';
import { Check, CheckResponsive, Pencil } from '@octanner/prism-icons';
import { StepIconRoot, IconWrapper } from './styles';

function StepIcon(props: StepIconProps) {
  const { active, completed, className, icon } = props;
  if (completed) {
    return (
      <StepIconRoot ownerState={{ active, completed }} className={className}>
        <div className="StepIcon-completedIcon">
          <Check className="checkIcon" />
          <CheckResponsive className="checkResponsiveIcon" />
          <Pencil className="pencilIcon" />
        </div>
      </StepIconRoot>
    );
  }
  return (
    <StepIconRoot ownerState={{ active, completed }} className={className}>
      <IconWrapper>{icon}</IconWrapper>
    </StepIconRoot>
  );
}

export default StepIcon;
