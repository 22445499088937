import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { FieldTypes, IdentityInfoProps } from '../Header/interfaces';
import Avatar from '../Avatar';
import { tannerGray } from '../ThemeProvider/colors';
import Tooltip from '../Tooltip';
import useTranslations from '../Header/hooks/useTranslations';
import { getFullName } from '@octanner/prism-utilities';
import { Container, IdentityContainer, NameWrapper, Text } from './styles';
import LayoutContext from '../context/Layout';
import { useEnabledPreferredPronouns } from '../Header/hooks/useEnabledPreferredPronouns';
import { useUserSearchConfigFields } from '../Header/hooks/useUserSearchConfigFields';

const IdentityInfo: React.FC<IdentityInfoProps> = ({
  identity,
  color = 'primary',
  isMobile = false,
}) => {
  const [currentIdentityQuery] = useContext(LayoutContext);
  const currentIdentity = currentIdentityQuery?.data?.currentIdentity;
  const customerUuid = currentIdentity?.customer?.customerUuid;
  const { data: enablePronounData } = useEnabledPreferredPronouns(customerUuid);
  const pronounsEnabled = enablePronounData?.ccWebPreferredPronouns?.enabled;
  const userSearchConfig = useUserSearchConfigFields(customerUuid);
  const customSearchFields =
    userSearchConfig?.data?.ccWebUserSearchConfigEnabledFields?.fields;

  const { t } = useTranslations();
  const textElementRef = useRef<HTMLDivElement | null>(null);
  const name = getFullName(identity, pronounsEnabled);
  const primaryColor = color === 'primary' ? tannerGray['900'] : '#ffffff';
  const secondaryColor = color === 'primary' ? tannerGray['600'] : '#ABABAE';
  const [disableTooltip, setDisableTooltip] = useState(false);
  const [searchFields, setSearchFields] = useState<string>('');

  useEffect(() => {
    if (!textElementRef.current) return;
    setDisableTooltip(
      !(
        textElementRef.current?.scrollWidth >
        textElementRef.current?.clientWidth
      ),
    );
  }, [searchFields, textElementRef]);

  const getSearchFields = useMemo(() => {
    const enabledFields = customSearchFields?.filter((field) => field.enabled);
    if (enabledFields?.length === 0) return [];
    const orderedEnabledFields = enabledFields?.sort(
      (a, b) => a.order - b.order,
    );
    const searchFields = orderedEnabledFields?.map((field) => field.fieldType);

    return searchFields?.map((field) => {
      switch (field) {
        case FieldTypes.EmailAddress:
          return identity?.email ? `${identity.email}` : '';
        case FieldTypes.EmployeeId:
          return identity?.uniqueId ? `${identity.uniqueId}` : '';
        case FieldTypes.BusinessUnit:
          return identity?.businessUnit?.buName
            ? `${identity.businessUnit.buName}`
            : '';
        case FieldTypes.LeaderName:
          return identity?.manager
            ? `${t('prism-header:leader', 'Leader')}: ${getFullName(
                identity.manager,
                pronounsEnabled,
              )}`
            : '';
        case FieldTypes.ParentBusinessUnit:
          return identity?.businessUnit?.parentBusinessUnit?.buName
            ? `${identity.businessUnit.parentBusinessUnit.buName}`
            : '';
        case FieldTypes.JobTitle:
          return identity?.jobTitle
            ? `${identity.jobTitle}`
            : `${identity.businessUnit?.buName}`;
        case FieldTypes.WorkLocation:
          return identity?.workLocation ? `${identity.workLocation}` : '';
        case FieldTypes.WorkLocationCondensed:
          return identity?.segmentOfficelocation
            ? `${identity.segmentOfficelocation}`
            : '';
      }
    });
  }, [customSearchFields, identity, t]);

  const displaySearchFields = useMemo(() => {
    const searchFields = getSearchFields;
    let hasField = false;
    const dotIncluded =
      searchFields?.map((field) => {
        if (field === '' || field === null) return field;
        else if (field !== '' && !hasField) {
          hasField = true;
          return field;
        } else if (hasField) return ` • ${field}`;
        else return field;
      }) || [];
    return dotIncluded.join('');
  }, [getSearchFields]);

  useEffect(() => {
    if (customSearchFields?.filter((field) => field.enabled).length === 0) {
      setDisableTooltip(true);
    } else {
      setSearchFields(displaySearchFields);
    }
  }, [customSearchFields, displaySearchFields]);

  return (
    <Container aria-label={name + ' ' + displaySearchFields}>
      <Tooltip
        title={displaySearchFields}
        placement="bottom-end"
        disableHoverListener={isMobile || disableTooltip}
        data-testid={'identity-tooltip:' + identity.uniqueId}
        disableInteractive
      >
        <IdentityContainer key={identity.identityUuid}>
          <Avatar src={identity.profile?.avatar} alt={name} size="md" />
          <NameWrapper>
            <Text
              color={primaryColor}
              data-testid={'identity-name:' + identity.uniqueId}
              aria-label={name}
            >
              {name}
            </Text>
            <Text
              secondary
              color={secondaryColor}
              ref={textElementRef}
              data-testid={'identity-search-fields:' + identity?.uniqueId}
              aria-label={searchFields}
            >
              {searchFields}
            </Text>
          </NameWrapper>
        </IdentityContainer>
      </Tooltip>
    </Container>
  );
};

export default IdentityInfo;
