import React, { FC, useContext } from 'react';
import { StoreButtonProps } from './types';
import { useTranslation } from 'react-i18next';
import {
  buttonFocusOverride,
  categoricMenuSX,
  MarginRightDiv,
  PointsBox,
  StyledSkeleton,
} from './styles';
import { Button } from '@mui/material';
import CategoricMenu from '../CategoricMenu';
import { ArrowTriangleDown } from '@octanner/prism-icons';
import LayoutContext from '../context/Layout';

const StoreButton: FC<StoreButtonProps> = ({
  multipleBanksObj: {
    hasMultipleBank,
    multipleBanks,
    suppliedSpecialPointName,
    vibesPointName,
    bank,
    bankUrl,
  },
  handleNavigation,
  vibePointLoading,
}) => {
  const { t } = useTranslation();
  const [__, layoutQuery, _] = useContext(LayoutContext);
  const layout = layoutQuery?.data;
  const hasRedeemPermission =
    layout?.hasRedeemAwardsPermission?.hasRedeemAwardsPermission;
  const multipleBankOrder = [
    t('prism-header:bank-accounts', 'Bank Accounts'),
    t('prism-header:access-codes', 'Access Codes'),
  ];

  const getCorrectPointName = () => {
    let correctName = '';
    if (vibesPointName) correctName = vibesPointName;
    else if (suppliedSpecialPointName) {
      correctName = suppliedSpecialPointName;
    } else {
      correctName = t('prism-header:points', 'Points');
    }
    const totalPoints =
      typeof bank?.points === 'number' ? bank?.points.toLocaleString() : 0;

    return `${totalPoints} ${correctName}`;
  };
  if (vibePointLoading) {
    return <StyledSkeleton />;
  }

  if (!hasRedeemPermission) {
    return null;
  }

  if (!hasMultipleBank) {
    return (
      <Button
        variant="text"
        component="a"
        disableRipple
        sx={buttonFocusOverride}
        data-testid="store-link"
        href={bankUrl}
        onClick={handleNavigation(bankUrl)}
        target="_blank"
      >
        {t('prism-header:store')}
        <PointsBox component="span">|{getCorrectPointName()}</PointsBox>
      </Button>
    );
  } else {
    return (
      <CategoricMenu
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        buttonText={
          <>
            <MarginRightDiv>
              {t('prism-header:store')}
              <PointsBox component="span">|{getCorrectPointName()}</PointsBox>
            </MarginRightDiv>
            <ArrowTriangleDown />
          </>
        }
        buttonVariant="text"
        buttonSx={buttonFocusOverride}
        data-testid="multiple-banks-menu"
        handleNavigationClick={handleNavigation}
        options={multipleBanks}
        sortOrder={multipleBankOrder}
        sx={categoricMenuSX}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      />
    );
  }
};

export default StoreButton;
