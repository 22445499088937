import React, { FC, useContext } from 'react';
import Button from '../Button';
import {
  AvatarSkeleton,
  buttonFocusOverride,
  FullWidthDivider,
  HeaderAvatar,
  LogoutMenuItem,
  ProfileMenuItem,
  StyledSkeleton,
} from './styles';
import { ProfileMenuProps } from './types';
import Menu from '../Menu';
import { MenuLinks } from './hooks/useLayoutLinks';
import { useTranslation } from 'react-i18next';
import LayoutContext from '../context/Layout';
import { useEnabledPreferredPronouns } from './hooks/useEnabledPreferredPronouns';
import { getFullName } from '@octanner/prism-utilities';

const ProfileMenu: FC<ProfileMenuProps> = ({
  logoutUrl,
  links,
  handleNavigation,
  profileMenuEl,
  setProfileMenuEl,
  currentIdentityLoading,
}) => {
  const { t } = useTranslation();
  const [currentIdentityQuery] = useContext(LayoutContext);
  const currentIdentity = currentIdentityQuery?.data?.currentIdentity;
  const customerUuid = currentIdentity?.customer?.customerUuid;
  const { data: enablePronounData } = useEnabledPreferredPronouns(customerUuid);
  const enablePronouns = enablePronounData?.ccWebPreferredPronouns?.enabled;
  const fullName = getFullName(currentIdentity, enablePronouns);
  const avatarSrc = currentIdentity?.profile?.avatar;

  const index = links.findIndex(({ id }) => id === MenuLinks.Logout);
  if (index >= 0) links.splice(index, 1);

  if (currentIdentityLoading) {
    return (
      <>
        <StyledSkeleton />
        <AvatarSkeleton variant="circular" />
      </>
    );
  }

  return (
    <>
      <Button
        disableRipple
        sx={buttonFocusOverride}
        variant="text"
        data-testid="current-user-name-header"
        aria-label={`${fullName} Profile`}
        aria-haspopup="true"
        aria-controls="profile-menu"
        aria-expanded={!!profileMenuEl}
        onClick={(e) => setProfileMenuEl(e.currentTarget as HTMLButtonElement)}
      >
        {fullName}
        <HeaderAvatar
          src={avatarSrc}
          alt={fullName}
          data-testid="current-user-avatar-header"
          size="sm"
        />
      </Button>
      <div id="profile-menu">
        <Menu
          id="profile-menu"
          anchorEl={profileMenuEl}
          anchorOrigin={{
            horizontal: 'right',
            vertical: 'bottom',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={!!profileMenuEl}
          onClose={() => setProfileMenuEl(null)}
          MenuListProps={{
            'aria-labelledby': 'profile-menu',
          }}
          PaperProps={{
            style: { minWidth: 200, padding: '0 8px' },
          }}
        >
          {links.map(({ id, translationString, url }) => (
            <ProfileMenuItem
              href={url}
              key={id}
              data-testid={`${translationString}-link`}
              aria-label={t(`prism-header:${translationString}`)}
              aria-required="true"
              onClick={handleNavigation(url)}
              autoFocus={id === MenuLinks.Profile}
            >
              {t(`prism-header:${translationString}`, translationString)}
            </ProfileMenuItem>
          ))}
          <FullWidthDivider aria-hidden="true" />
          <LogoutMenuItem
            href={logoutUrl ?? '/logout'}
            data-testid="logout-link"
            aria-label={t('prism-header:logout', 'Logout')}
            aria-required="true"
            onClick={handleNavigation(logoutUrl ?? '/logout')}
          >
            {t('prism-header:logout', 'Logout')}
          </LogoutMenuItem>
        </Menu>
      </div>
    </>
  );
};

export default ProfileMenu;
