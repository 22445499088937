import React from 'react';
import { StyledLinearProgress } from './styles';
import { LinearProgressProps } from './types';

export { linearProgressClasses } from '@mui/material/LinearProgress';
// Functional component LinearProgress
const LinearProgress: React.FC<LinearProgressProps> = ({
  value,
  variant,
  style,
  sx,
  classes,
  className,
  key,
  color,
  valueBuffer,
  id,
  tabIndex,
  'data-testid': dataTestId,
  'aria-label': ariaLabel,
  'aria-labelledby': ariaLabelledBy,
  'aria-describedby': ariaDescribedBy,
  'aria-hidden': ariaHiddenBy,
  'aria-valuenow': ariaValueNow,
  'aria-valuemin': ariaValueMin,
  'aria-valuemax': ariaValueMax,
}) => {
  return (
    <StyledLinearProgress
      variant={variant}
      value={value}
      style={style}
      sx={sx}
      classes={classes}
      className={className}
      key={key}
      data-testid={dataTestId}
      color={color}
      valueBuffer={valueBuffer}
      id={id}
      tabIndex={tabIndex}
      aria-label={ariaLabel}
      aria-labelledby={ariaLabelledBy}
      aria-describedby={ariaDescribedBy}
      aria-hidden={ariaHiddenBy}
      aria-valuemin={ariaValueMin}
      aria-valuemax={ariaValueMax}
      aria-valuenow={ariaValueNow}
    />
  );
};

export default LinearProgress;
