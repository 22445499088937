import React, { useContext } from 'react';
import {
  HeaderCell,
  LogoCell,
  StyledCultureCloudLogoLink,
  StyledLogoImage,
  StyledLogoLink,
  StyledSkeleton,
} from './styles';
import { LogoLinkProps } from './types';
import LayoutContext from '../context/Layout';
import CultureCloudWhiteLogo from './CultureCloudWhiteLogo';

const LogoLink: React.FC<LogoLinkProps> = ({
  handleNavigation,
  isCultureCloud,
}) => {
  const [currentIdentityQuery, layoutQuery] = useContext(LayoutContext);
  const customerName =
    currentIdentityQuery?.data?.currentIdentity?.customer?.name;
  const logoValue = layoutQuery?.data?.theme?.logo?.value;
  const isCurrentPage = (url: string) => window.location.pathname === url;
  if (isCultureCloud) {
    return (
      <LogoCell>
        <StyledCultureCloudLogoLink
          href="/"
          onClick={handleNavigation('/')}
          aria-label={`Culture Cloud logo. ${
            isCurrentPage('/') ? 'Same Page' : 'Go to Home Page'
          }`}
        >
          <CultureCloudWhiteLogo />
        </StyledCultureCloudLogoLink>
      </LogoCell>
    );
  }
  return (
    <HeaderCell>
      {!logoValue ? (
        <StyledSkeleton />
      ) : (
        <StyledLogoLink
          href="/"
          onClick={handleNavigation('/')}
          aria-label={`${customerName || 'Company'} logo. ${
            isCurrentPage('/') ? 'Same Page' : 'Go to Home Page'
          }`}
        >
          <StyledLogoImage
            src={logoValue}
            alt={`${customerName || 'Company'} logo`}
          />
        </StyledLogoLink>
      )}
    </HeaderCell>
  );
};

export default LogoLink;
