import React from 'react';
import find from 'lodash/find';
import { Layout, Link } from '../interfaces';

export enum PrimaryLinks {
  Home = 'HOME',
  MyTeam = 'MY_TEAM',
  Initiatives = 'INITIATIVES',
  Programs = 'PROGRAMS',
  OneToOnes = 'ALIGN',
  Yearbook = 'YB',
  Anniversaries = 'ANNIVERSARIES',
  Wellbeing = 'WELLBEING',
}

export enum SecondaryLinks {
  Admin = 'ADMIN',
}

export enum MenuLinks {
  Profile = 'PROFILE',
  Settings = 'SETTINGS',
  RewardCode = 'REWARD_CODE',
  ShippingAddress = 'SHIPPING_ADDRESS',
  MyHistory = 'MY_HISTORY',
  Logout = 'LOGOUT',
}

const useLayoutLinks = (layout?: Layout) => {
  const links = layout?.cultureCloud?.ccHeader?.links;

  const result = React.useMemo(() => {
    const base = {
      primaryLinks: [] as Link[],
      secondaryLinks: [] as Link[],
      menuLinks: [] as Link[],
    };

    if (links) {
      const primaryKeys = Object.values(PrimaryLinks);
      const secondaryKeys = Object.values(SecondaryLinks);
      const menuKeys = Object.values(MenuLinks);

      primaryKeys.forEach((key) => {
        const link = find(links, (link) => link.id === key);
        if (link) base.primaryLinks.push(link);
      });
      secondaryKeys.forEach((key) => {
        const link = find(links, (link) => link.id === key);
        if (link) base.secondaryLinks.push(link);
      });
      menuKeys.forEach((key) => {
        const link = find(links, (link) => link.id === key);
        if (link?.url) base.menuLinks.push(link);
      });
    }

    return base;
  }, [links]);

  return result;
};

export default useLayoutLinks;
