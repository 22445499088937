import styled from '@emotion/styled';
import MuiStepLabel from '@mui/material/StepLabel';

const StepLabel = styled(MuiStepLabel)(() => ({
  '&& .MuiStepLabel-alternativeLabel': {
    fontSize: 12,
    marginTop: 4,
    '& .Mui-active': {
      fontWeight: 800,
    },
  },
}));

export default StepLabel;
