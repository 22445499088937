import { Tab } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import Tabs from '../Tabs';
import { useTranslation } from 'react-i18next';
import { TabRowProps } from './types';
import { HeaderTabSkeleton, tabFocusOverride } from './styles';
import { PrimaryLinks } from './hooks/useLayoutLinks';
import { Link } from './interfaces';

const TabRow: FC<TabRowProps> = ({
  handleNavigation,
  vibePointLoading,
  links,
  newTabProps,
  routerPath,
}) => {
  const { t } = useTranslation();
  const [activeLink, setActiveLink] = useState<string | false>(false);

  useEffect(() => {
    const pathname = routerPath || window.location.pathname;
    const isActiveTab = (link: Link) =>
      link.url.length > 1
        ? pathname.startsWith(link.url)
        : pathname === link.url;
    const primaryLink = links.primaryLinks.find(isActiveTab);

    setActiveLink(primaryLink?.url || false);
  }, [links.primaryLinks, routerPath]);

  const LoadingTabs = () => (
    <Tabs value={0} aria-role="tablist">
      {Array(3)
        .fill('')
        .map((_, i) => (
          <Tab
            key={i}
            label={<HeaderTabSkeleton />}
            aria-label={t('prism-header:loading', 'Loading')}
          />
        ))}
    </Tabs>
  );

  if (vibePointLoading) {
    return <LoadingTabs />;
  }

  return (
    <Tabs
      value={activeLink}
      textColor="secondary"
      TabIndicatorProps={{ sx: { background: 'white' } }}
      aria-role="tablist"
    >
      {links.primaryLinks.map(({ id, translationString, url }) => (
        <Tab
          key={id}
          value={url}
          className="fs-unmask"
          component="a"
          sx={tabFocusOverride}
          disableRipple
          href={url}
          data-testid={`${translationString}-link`}
          onClick={handleNavigation(url)}
          label={t(`prism-header:${translationString}`)}
          {...(id === PrimaryLinks.Wellbeing && newTabProps)}
        />
      ))}
    </Tabs>
  );
};

export default TabRow;
