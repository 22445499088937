import { tannerGray, tannerBlue } from '../ThemeProvider/colors';
import { default as MuiLinearProgress } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';

export const StyledLinearProgress = styled(MuiLinearProgress)(({ color }) => ({
  minWidth: '40px',
  backgroundColor: tannerGray['200'],
  '& .MuiLinearProgress-bar': {
    ...(color === undefined && { backgroundColor: tannerBlue['500'] }),
  },
}));
