import { useMutation } from '@apollo/client';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { UPDATE_LANGUAGE } from '../../ThemeProvider';
import { Identity, Locale } from '../interfaces';

export const selectLanguage = (
  currentIdentity?: Identity,
  locales?: Locale[],
) => {
  return (
    currentIdentity?.language ||
    (locales?.some((locale) => locale.locale.code === navigator.language) &&
      navigator.language) ||
    'en-US'
  );
};

const getTranslations = (lang: string) =>
  fetch('https://www.culturecloud.com/t/prism-header', {
    headers: {
      'accept-language': lang,
    },
  }).then(async (res) => {
    if (res.ok) {
      const updateLang = res.headers.get('content-language');
      const data = await res.json();
      return { lang: updateLang, data };
    }
    throw res;
  });

const useTranslations = (currentIdentity?: Identity, locales?: Locale[]) => {
  const [updateLanguage] = useMutation(UPDATE_LANGUAGE);
  const [lang, setLang] = React.useState<string | null>(null);
  const reactI18n = useTranslation();

  React.useEffect(() => {
    if (!currentIdentity || !locales) return;

    const lang = selectLanguage(currentIdentity, locales);

    getTranslations(lang)
      .then(({ data, lang }) => {
        setLang(lang);
        if (!currentIdentity?.language) {
          updateLanguage({
            variables: { id: currentIdentity?.identityUuid, language: lang },
          });
        }
        reactI18n.i18n
          .addResourceBundle('en', 'prism-header', data)
          .emit('languageChanged');
      })
      .catch(() => console.error('Could not load header translations!'));
  }, [reactI18n.i18n, currentIdentity, updateLanguage, locales]);

  return { ...reactI18n, lang };
};

export const usePrismTranslationsStandalone = () => {
  const [lang, setLang] = React.useState<string | null>(null);
  const reactI18n = useTranslation();
  React.useEffect(() => {
    const lang = navigator.languages?.[0] || navigator.language;
    getTranslations(lang)
      .then(({ data, lang }) => {
        setLang(lang);
        reactI18n.i18n
          .addResourceBundle('en', 'prism-header', data)
          .emit('languageChanged');
      })
      .catch(() => console.error('Could not load header translations!'));
  }, [reactI18n.i18n]);

  return { ...reactI18n, lang };
};

export default useTranslations;
