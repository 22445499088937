import { InformationCircle } from '@octanner/prism-icons';
import { styled } from '@mui/system';
import { tannerGray } from '../ThemeProvider/colors';
export const TitleContainer = styled('div')({
  width: '200px',
});
export const IconWrapper = styled('div')(({ theme }) => ({
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '50%',
  outline: 'none',
  '&:focus-visible': {
    outline: '3px solid #0060e0',
    outlineOffset: '3px',
    svg: {
      color: tannerGray['600'],
    },
  },
}));

export const InfoCircleContainer = styled(InformationCircle)(({ theme }) => ({
  color: tannerGray['400'],
  '&:hover': {
    color: tannerGray['600'],
  },
}));
