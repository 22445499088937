import { gql, useQuery } from '@apollo/client';
import { VibeDerivedDetails } from '../interfaces';

export const GET_VIBES_INFO = gql`
  query VibeGetDerivedVibeDetails($input: VibeGetDerivedVibeDetailsInput!) {
    vibeGetDerivedVibeDetails(input: $input) {
      attributes {
        text {
          welcomeMessage
          pointsNamePlural
          pointsNameSingular
        }
      }
      vibeName
      vibeUuid
    }
  }
`;

export const useGetPointName = (
  customerUuid?: string,
  identityUuid?: string,
  language?: string | null,
) => {
  const { data, loading, error } = useQuery<VibeDerivedDetails>(
    GET_VIBES_INFO,
    {
      fetchPolicy: 'no-cache',
      variables: {
        input: {
          companyId: customerUuid,
          userId: identityUuid,
          language,
        },
      },
      skip: !customerUuid || !identityUuid,
    },
  );
  return { data, loading, error };
};
