import { gql, useQuery } from '@apollo/client';
import { PreferredPronouns } from '../interfaces';

export const PREFERRED_PRONOUNS_ENABLED = gql`
  query CcWebPreferredPronouns($input: CCWebDisplayedPronounInput!) {
    ccWebPreferredPronouns(input: $input) {
      enabled
    }
  }
`;

export const useEnabledPreferredPronouns = (customerUuid?: string) => {
  const { data, loading, error } = useQuery<PreferredPronouns>(
    PREFERRED_PRONOUNS_ENABLED,
    { variables: { input: { customerUuid } }, skip: !customerUuid },
  );
  return { data, loading, error };
};
