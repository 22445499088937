import React, { useMemo } from 'react';
import { ThemeProvider, useMediaQuery } from '@mui/material';
import LayoutContext from '../context/Layout';
import useLayoutLinks from './hooks/useLayoutLinks';
import useTranslations from './hooks/useTranslations';
import useHeaderTheme from './hooks/useHeaderTheme';
import { UserBank } from './interfaces';
import ImpersonationBanner from '../ImpersonationBanner';
import useCatalogLink from '../hooks/useCatalogLink';
import useAnalytics from './hooks/useAnalytics';
import { useFeatureFlag, useMaintenancePage } from '@octanner/prism-utilities';
import { DateTime } from 'luxon';
import { HeaderContainer } from './styles';
import { HeaderProps, MultipleBankAndAccessCodes } from './types';
import VariableHeader from './VariableHeader';
import MobileContainer from './MobileContainer';

const Header: React.FC<HeaderProps> = ({
  fullStoryOrg,
  isImpersonating,
  specialPointName,
  onStopImpersonation,
  routerPath,
  routerPush,
  routerRoutes = [],
  referrerUrl,
  variant = 'normal',
  logoutUrl,
  ...props
}) => {
  const headerTheme = useHeaderTheme();
  const isMobile = useMediaQuery('@media (max-width: 768px)');
  const [currentIdentityQuery, layoutQuery, localeQuery] =
    React.useContext(LayoutContext);
  const { t, lang } = useTranslations(
    currentIdentityQuery?.data?.currentIdentity,
    localeQuery?.data?.customerCoreLocaleMapsByCustomer?.nodes,
  );
  const currentIdentity = currentIdentityQuery?.data?.currentIdentity;
  const links = useLayoutLinks(layoutQuery?.data);
  const bankUrl = useCatalogLink({ referrerUrl, lang });
  const featureFlag = useFeatureFlag<boolean>('ccui-multiple-banks-p-240315');
  const multipleBanksFeatureFlag = useMemo(
    () =>
      featureFlag && featureFlag[0] === true && featureFlag[1] === true
        ? true
        : false,
    [featureFlag],
  );

  const testRoute = (url: string) => (route: string) =>
    route.length > 1 ? url.startsWith(route) : url === route;

  const layout = layoutQuery?.data;
  const headerView = variant === 'normal' ? true : false;

  const bank =
    layout?.bankBalance?.find((bank) => bank.defaultBank) ||
    layout?.bankBalance?.[0];

  const getBankUrl = (bank: UserBank) => {
    if (!currentIdentity) return '';
    const queryParams = new URLSearchParams({
      countryCode:
        currentIdentity.profile?.catalogCoreCountry?.iso2Code ||
        currentIdentity.profile?.homeCoreCountry?.iso2Code ||
        '',
      programId: bank?.programId?.toString() || '',
      refererUrl: referrerUrl,
      locale: (lang || navigator.languages?.[0] || navigator.language).replace(
        '-',
        '_',
      ),
    });
    return `/catalog?${queryParams}`;
  };

  const hasMultipleBank =
    layout?.bankBalance &&
    layout.bankBalance.length > 1 &&
    bank &&
    multipleBanksFeatureFlag;

  const multipleBanks =
    layout?.bankBalance &&
    layout.bankBalance.map((bank) => {
      const accessCodes =
        bank.accessCodeDetails &&
        bank.accessCodeDetails.map((access) => {
          const dateTime = DateTime.fromFormat(
            access.expiresAt,
            'yyyy-MM-dd hh:mm:ss.S',
          );
          return {
            category: t('prism-header:access-codes', 'Access Codes'),
            primary: access.accessCode,
            secondary: t(
              'prism-header:expiring-access-codes',
              'Expires {accessCodeDate}',
              {
                accessCodeDate: dateTime.toLocaleString(DateTime.DATE_SHORT),
              },
            ),
            url: getBankUrl(bank),
          };
        });
      return {
        category: t('prism-header:bank-accounts', 'Bank Accounts'),
        primary: bank.bankName,
        secondary: bank.points?.toString(),
        url: getBankUrl(bank),
        accessCodes,
        defaultBank: bank.defaultBank,
      };
    });
  // sort multiple banks by default bank
  multipleBanks?.sort((a, b) => {
    if (a.defaultBank) return -1;
    if (b.defaultBank) return 1;
    return 0;
  });
  const banksWithAccessCodes = multipleBanks?.filter(
    (o) => o.accessCodes && o.accessCodes.length > 0,
  );
  const accessCodes = banksWithAccessCodes
    ?.map((bank) => {
      return bank.accessCodes || [];
    })
    .flat() as MultipleBankAndAccessCodes[];

  // sort access codes by expiration date
  accessCodes?.sort((a, b) => {
    const dateA = a.secondary.split(' ')[1];
    const dateB = b.secondary.split(' ')[1];
    return (
      new Date(dateA).getTime() - new Date(dateB).getTime() ||
      a.secondary.localeCompare(b.secondary)
    );
  });

  const vettedMultipleBanks = multipleBanks?.map((bank) => {
    return {
      category: bank.category,
      primary: bank.primary,
      secondary: bank.secondary,
      url: bank.url,
    };
  }) as MultipleBankAndAccessCodes[];

  useMaintenancePage();

  useAnalytics({
    currentIdentity,
    fullStoryOrg,
    links,
    bank,
  });

  return (
    <ThemeProvider theme={headerTheme}>
      {currentIdentity && isImpersonating && (
        <ImpersonationBanner
          identity={currentIdentity}
          onStopImpersonation={onStopImpersonation}
        />
      )}
      <HeaderContainer {...props}>
        {isMobile ? (
          <MobileContainer
            multipleBanksObj={{
              hasMultipleBank: hasMultipleBank,
              multipleBanks: vettedMultipleBanks,
              accessCodes,
              bankUrl,
            }}
            routerObj={{ routerPush, routerRoutes, testRoute }}
            logoutUrl={logoutUrl}
          />
        ) : (
          <VariableHeader
            headerView={headerView}
            multipleBanksObj={{
              hasMultipleBank: headerView && hasMultipleBank,
              multipleBanks: vettedMultipleBanks?.concat(accessCodes),
              suppliedSpecialPointName: specialPointName,
              bank: bank,
              bankUrl,
            }}
            routerObj={{ routerPush, routerRoutes, routerPath, testRoute }}
          />
        )}
      </HeaderContainer>
    </ThemeProvider>
  );
};

export default Header;
