import React, { FC, useState } from 'react';
import IdentityInfo from '../IdentityInfo';
import SingleSearch from '../SingleSearch';
import {
  HeaderCell,
  iconButtonOverride,
  SearchButtonIcon,
  SingleSearchStyles,
} from './styles';
import { SearchExpandProps } from './types';
import { Search as SearchIcon } from '@octanner/prism-icons';
import { useIdentitySearch } from './identitySearch';
import { Identity } from './interfaces';
import { useTranslation } from 'react-i18next';

const SearchExpand: FC<SearchExpandProps> = ({
  searchOpen,
  handleSearchOpen,
  routerObj: { routerPush, routerRoutes, testRoute },
}) => {
  const { t } = useTranslation();

  const [searchText, setSearchText] = useState('');
  const identitySearchQuery = useIdentitySearch(searchText);
  const searchOptions = identitySearchQuery?.data || [];

  const handleClose = () => {
    setSearchText('');
    handleSearchOpen(false);
  };

  const selectIdentity = (_: any, identity: Identity | string) => {
    if (typeof identity !== 'string') {
      const url = `/profile/${identity.identityUuid}`;
      if (routerPush && routerRoutes.some(testRoute(url))) {
        routerPush(url);
      } else {
        window.location.assign(url);
      }
    }
    handleClose();
  };

  if (!searchOpen) {
    return (
      <HeaderCell>
        <SearchButtonIcon
          color="secondary"
          aria-label={t('prism-header:search-people', 'Search people')}
          onClick={() => handleSearchOpen(true)}
          sx={iconButtonOverride}
          data-testid="search-icon"
        >
          <SearchIcon color="secondary" />
        </SearchButtonIcon>
      </HeaderCell>
    );
  }
  return (
    <SingleSearch
      options={searchOptions}
      filterOptions={(option) => option}
      onChange={(e, val) => {
        selectIdentity(e, val);
        handleClose();
      }}
      data-testid="search-cc-header"
      loading={identitySearchQuery?.loading}
      placeholder={t('prism-header:search-people', 'Search people')}
      onInputChange={(e, _, reason) => {
        if (reason === 'input') {
          setSearchText((e.target as HTMLInputElement).value);
        } else if (reason == 'clear') {
          handleClose();
        }
      }}
      onBlur={() => {
        handleClose();
      }}
      inputValue={searchText}
      sx={SingleSearchStyles}
      renderOption={(props, option) => (
        <li {...props}>
          <IdentityInfo identity={option} color="secondary" />
        </li>
      )}
    />
  );
};

export default SearchExpand;
