import React from 'react';
import { IconWrapper, TitleContainer } from './styles';
import { InformationToolTipProps } from './types';
import { InfoCircleContainer } from './styles';
import Tooltip from '../Tooltip';

const InformationToolTip: React.FC<InformationToolTipProps> = ({
  title,
  placement,
  disableFocusListener,
  disableHoverListener,
  disableTouchListener,
  enterDelay,
  enterNextDelay,
  enterTouchDelay,
  id,
  leaveDelay,
  leaveTouchDelay,
  onClose,
  onOpen,
  open,
  PopperComponent,
  PopperProps,
  TransitionComponent,
  TransitionProps,
  style,
  sx,
  'aria-label': ariaLabel,
  'aria-labelledby': ariaLabelledBy,
  'aria-describedby': ariaDescribedBy,
  classes,
  key,
  'data-testid': dataTestId,
  'aria-live': ariaLive,
  arrow,
  describeChild,
  componentsProps,
  components,
  disableInteractive,
  followCursor,
  slotProps,
  slots,
}) => {
  return (
    <Tooltip
      title={title ? <TitleContainer>{title}</TitleContainer> : ''}
      placement={placement}
      disableFocusListener={disableFocusListener}
      disableHoverListener={disableHoverListener}
      disableTouchListener={disableTouchListener}
      enterDelay={enterDelay}
      enterNextDelay={enterNextDelay}
      enterTouchDelay={enterTouchDelay}
      id={id}
      leaveDelay={leaveDelay}
      leaveTouchDelay={leaveTouchDelay}
      onClose={onClose}
      onOpen={onOpen}
      open={open}
      PopperComponent={PopperComponent}
      PopperProps={PopperProps}
      TransitionComponent={TransitionComponent}
      TransitionProps={TransitionProps}
      style={style}
      sx={sx}
      classes={classes}
      key={key}
      data-testid={dataTestId}
      aria-label={ariaLabel}
      aria-labelledby={ariaLabelledBy}
      aria-describedby={ariaDescribedBy}
      aria-live={ariaLive}
      arrow={arrow}
      componentsProps={componentsProps}
      components={components}
      disableInteractive={disableInteractive}
      describeChild={describeChild}
      followCursor={followCursor}
      slotProps={slotProps}
      slots={slots}
    >
      <IconWrapper tabIndex={0}>
        <InfoCircleContainer sx={sx} style={style} />
      </IconWrapper>
    </Tooltip>
  );
};
export default InformationToolTip;
