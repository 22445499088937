import {
  Timeline,
  timelineOppositeContentClasses,
  TimelineContent,
} from '@mui/lab';
import Box from '../Box';
import styled from '@emotion/styled';
import Tag from '../Tag';
import { ITag } from '../Tag/types';
import { tannerGray } from '../ThemeProvider/colors';
import { Position, TimelineContentContainerProps } from './types';

export const EventContainer = styled(Box)({
  justifySelf: 'space-between',
});

export const CustomFooter = styled(Box)({
  width: '100%',
});

export const TimelineContentContainer = styled(Box, {
  shouldForwardProp: (prop) =>
    prop !== 'shouldHaveBorderBottom' && prop !== 'variant',
})<TimelineContentContainerProps>(({ shouldHaveBorderBottom, variant }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'space-between',
  paddingBottom: shouldHaveBorderBottom ? '8px' : '16px',
  borderBottom:
    variant === 'simple'
      ? 'none'
      : shouldHaveBorderBottom
      ? `1px solid ${tannerGray[200]}`
      : 'none',
}));

export const TagContainer = styled('div')<{ position: Position }>(
  ({ position }) => ({
    display: 'flex',
    gap: '4px',
    flexWrap: 'wrap',
    margin: '8px 0',
    maxWidth: '775px',
    justifyContent: position === 'right' ? 'flex-start' : 'flex-end',
  }),
);

export const StyledTag = styled(Tag)<ITag>({
  width: 'fit-content',
  maxWidth: '250px',
});

export const StyledTimeline = styled(Timeline)({
  [`& .${timelineOppositeContentClasses.root}`]: { flex: 0.2 },
});

export const StyledTimelineContent = styled(TimelineContent)`
  &.MuiTypography-root.MuiTypography-body1.MuiTimelineContent-root.MuiTimelineContent-positionRight {
    padding-bottom: 0px;
  }
`;
