import React from 'react';
import App from './app';
import { RadixProvider } from '@modulz/radix';
import { ThemeProvider } from '@octanner/prism-core';
import {
  CURRENT_IDENTITY_QUERY,
  LAYOUT_QUERY,
  LOCALE_QUERY,
} from '@octanner/prism-core/src/ThemeProvider';
import { BELL_INDICATOR_ACTIVE } from '@octanner/prism-core/src/Header/hooks/useIsBellActive';
import { IDENTITY_SEARCH } from '@octanner/prism-core/src/Header/identitySearch';
import { USER_SEARCH_CONFIG_ENABLED_FIELDS_QUERY } from '@octanner/prism-core/src/Header/hooks/useUserSearchConfigFields';
import { PREFERRED_PRONOUNS_ENABLED } from '@octanner/prism-core/src/Header/hooks/useEnabledPreferredPronouns';
import { GET_VIBES_INFO } from '@octanner/prism-core/src/Header/hooks/useGetPointName';
import { ApolloProvider } from '@apollo/client';
import { createMockClient } from 'mock-apollo-client';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import OskoEnergyLogo from './components/OskoEnergyLogo.svg';
import { FieldTypes } from '@octanner/prism-core/src/Header/interfaces';
import { FieldNames } from '@octanner/prism-core/src/Header/interfaces';

const mockClient = createMockClient();

mockClient.setRequestHandler(IDENTITY_SEARCH, () =>
  Promise.resolve({
    data: {
      identitySearch: {
        identities: [
          {
            businessUnit: {
              buName: 'Shipping',
              parentBusinessUnit: {
                buName: 'Logistics',
              },
              __typename: 'BusinessUnit',
            },
            customer: {
              customerUuid: 'customer-id',
              name: 'Customer Name',
              stpNumber: 'stp-number',
              features: [],
            },
            id: '3ee0c695-e76f-4884-9611-573d1ebc030a',
            identityUuid: '3ee0c695-e76f-4884-9611-573d1ebc030a',
            uniqueId: '3ee0c695-e76f-4884-9611-573d1ebc030a',
            firstName: 'Gaynor',
            lastName: 'Oakley',
            preferredName: 'Gaynor',
            email: 'octmigration@octanner.mailinator.com',
            workLocation: 'Smallsville, Texas, USA',
            workLocationName: 'Smallsville',
            segmentOfficelocation: 'Smalls',
            jobTitle: 'Shipper Level 2',
            profile: {
              avatar:
                'https://oc-images-api.s3.amazonaws.com/production/users/avatars/002/962/576/df8124244697340a77afafe5ab4887b910b9c305.JPG?1475763740',
              pronoun: 'He/Him',
              __typename: 'Profile',
            },
            __typename: 'Identity',
            manager: {
              id: 'b96ffdba-5d74-4b4f-9d2e-9d81e711d2b1',
              firstName: 'Quark',
              lastName: 'Yooden',
              preferredName: 'Quark',
              uniqueId: 'b96ffdba-5d74-4b4f-9d2e-9d81e711d2b1',
              identityUuid: 'b96ffdba-5d74-4b4f-9d2e-9d81e711d2b1',
              email: 'octmigration@octanner.mailinator.com',
              workLocation: 'Smallsville, Texas, USA',
              workLocationName: 'Smallsville',
              segmentOfficelocation: 'Smalls',
              jobTitle: 'Shipper Level 3',
              profile: {
                avatar:
                  'https://oc-images-api.s3.amazonaws.com/production/users/avatars/002/962/401/75a523a3baa06ef90fe10ced1a11baa8731bc09c.JPg?1478206654',
                pronoun: 'They/Them',
                __typename: 'Profile',
              },
              __typename: 'Identity',
            },
          },
          {
            businessUnit: {
              buName: 'Sls-Virginia Strategic',
              parentBusinessUnit: {
                buName: 'Strategies',
              },
              __typename: 'BusinessUnit',
            },
            customer: {
              customerUuid: 'customer-id',
              name: 'Customer Name',
              stpNumber: 'stp-number',
              features: [],
            },
            id: 'e645dcbb-3382-48da-a5d7-a0dae2893f2f',
            identityUuid: 'e645dcbb-3382-48da-a5d7-a0dae2893f2f',
            uniqueId: 'e645dcbb-3382-48da-a5d7-a0dae2893f2f',
            firstName: 'George',
            lastName: 'Pinely',
            preferredName: 'George',
            email: 'octmigration@octanner.mailinator.com',
            workLocation: 'Virginia City, Virginia, USA',
            workLocationName: 'Virginia City',
            segmentOfficelocation: 'VC',
            jobTitle: 'Strategist 1',
            profile: {
              avatar:
                'https://oc-images-api.s3.amazonaws.com/production/users/avatars/002/961/981/12a8e9618dff94cc2de7ab0642ac239cd3b381f2.jpg?1415122287',
              pronoun: '',
              __typename: 'Profile',
            },
            __typename: 'Identity',
            manager: {
              id: 'f49d1501-5c51-497f-97e1-ee719020d3a8',
              firstName: 'Xorn',
              lastName: 'Parker',
              preferredName: 'Xorn',
              uniqueId: 'f49d1501-5c51-497f-97e1-ee719020d3a8',
              identityUuid: 'f49d1501-5c51-497f-97e1-ee719020d3a8',
              email: 'octmigration@octanner.mailinator.com',
              workLocation: 'Virginia City, Virginia, USA',
              workLocationName: 'Virginia City',
              segmentOfficelocation: 'VC',
              jobTitle: 'Strategist 2',
              profile: {
                avatar:
                  'https://oc-images-api.s3.amazonaws.com/production/users/avatars/002/961/853/cf45550794883ae9521e08f86d510fcb3959d35f.jpg?1475184194',
                pronoun: null,
                __typename: 'Profile',
              },
              __typename: 'Identity',
            },
          },
        ],
      },
    },
  }),
);

mockClient.setRequestHandler(
  CURRENT_IDENTITY_QUERY,
  (props) =>
    new Promise((resolve) =>
      window.setTimeout(() => {
        resolve({
          data: {
            currentIdentity: {
              nonFedFlag: false,
              nonCredFlag: false,
              profile: {
                avatar:
                  'https://www.pngarts.com/files/3/Employee-Avatar-PNG-Free-Download.png',
                pronoun: 'He/Him',
                catalogCoreCountry: { iso2Code: 'US' },
                homeCoreCountry: { iso2Code: 'US' },
              },
              dateOfBirth: '8-12-1997',
              email: 'test@test.com',
              id: 'currentIdentity-id',
              identityUuid: 'currentIdentity-id',
              firstName: 'Bert',
              lastName: 'Large',
              language: 'en-US',
              jobTitle: 'Software Engineer',
              workLocation: 'Salt Lake City, UT',
              segmentOfficelocation: 'SLC',
              preferredName: 'Bert',
              businessUnit: {
                buName: 'Business Unit Name',
                parentBusinessUnit: {
                  buName: 'Parent Business Unit Name',
                },
              },
              customer: {
                id: 'customer-id',
                customerUuid: 'customer-id',
                name: 'Customer Name',
                stpNumber: 'stp-number',
                features: [],
              },
              isManager: true,
              manager: {
                id: 'manager-id',
                identityUuid: 'manager-id',
                firstName: 'Manager',
                lastName: 'Doe',
                preferredName: 'Manager',
              },
            },
          },
        });
      }, 1500),
    ),
);

mockClient.setRequestHandler(
  LAYOUT_QUERY,
  () =>
    new Promise((resolve) => {
      window.setTimeout(
        () =>
          resolve({
            data: {
              bankBalance: [
                {
                  accessCodeDetails: [
                    {
                      accessCode: '09k3aq33g96v',
                      expiresAt: '3000-01-30 10:52:00.0',
                      status: 'A',
                      __typename: 'AccessCodeDetail',
                    },
                    {
                      accessCode: 'lh8fj4os34tf',
                      expiresAt: '3000-01-29 10:52:00.0',
                      status: 'A',
                      __typename: 'AccessCodeDetail',
                    },
                  ],
                  activeAccessCodeCount: 2,
                  bankName: 'POINTS',
                  defaultBank: true,
                  id: 1,
                  points: 7838,
                  programId: 12345,
                },
                {
                  accessCodeDetails: [
                    {
                      accessCode: 'jkfvuiren34',
                      expiresAt: '3000-01-28 10:52:00.0',
                      status: 'A',
                      __typename: 'AccessCodeDetail',
                    },
                  ],
                  activeAccessCodeCount: 1,
                  bankName: 'SNICKERS',
                  defaultBank: false,
                  id: 2,
                  points: 3000,
                  programId: 678910,
                },
                {
                  accessCodeDetails: null,
                  activeAccessCodeCount: 0,
                  bankName: 'SMILES',
                  defaultBank: false,
                  id: 3,
                  points: null,
                  programId: 246810,
                },
              ],
              cultureCloud: {
                ccHeader: {
                  links: [
                    {
                      id: 'MY_TEAM',
                      translationString: 'my-team',
                      url: '/myteam',
                    },
                    { id: 'HOME', translationString: 'home', url: '/' },
                    {
                      id: 'INITIATIVES',
                      translationString: 'initiatives',
                      url: '/initiatives',
                    },
                    {
                      id: 'YB',
                      translationString: 'anniversaries',
                      url: '/anniversaries',
                    },
                    {
                      id: 'WELLBEING',
                      translationString: 'wellbeing',
                      url: '/wellbeing',
                    },
                    { id: 'ADMIN', translationString: 'admin', url: '/admin' },
                    {
                      id: 'PROFILE',
                      translationString: 'profile',
                      url: '/profile',
                    },
                    {
                      id: 'SETTINGS',
                      translationString: 'settings',
                      url: '/settings',
                    },
                    {
                      id: 'MY_HISTORY',
                      translationString: 'my-history',
                      url: '/myhistory',
                    },
                    {
                      id: 'LOGOUT',
                      translationString: 'logout',
                      url: '/logout',
                    },
                  ],
                },
              },
              theme: {
                themeId: 'theme-id',
                logo: {
                  value: OskoEnergyLogo,
                },
                canvasImage: {
                  value: OskoEnergyLogo,
                },
                primaryColor: { value: '#7942DD' },
              },
              hasRedeemAwardsPermission: {
                hasRedeemAwardsPermission: true,
              },
              ccIsBellIndicatorActive: {
                isBellIndicatorActive: true,
              },
            },
          }),
        1500,
      );
    }),
);

mockClient.setRequestHandler(
  BELL_INDICATOR_ACTIVE,
  () =>
    new Promise((resolve) => {
      window.setTimeout(
        () =>
          resolve({
            data: {
              ccIsBellIndicatorActive: {
                isBellIndicatorActive: true,
              },
            },
          }),
        500,
      );
    }),
);

mockClient.setRequestHandler(
  LOCALE_QUERY,
  () =>
    new Promise((resolve) => {
      window.setTimeout(
        () =>
          resolve({
            data: {
              customerCoreLocaleMapsByCustomer: {
                nodes: [
                  {
                    locale: {
                      code: 'en-GB',
                      languageDescription: 'English - (Canada/UK)',
                    },
                  },
                  {
                    locale: {
                      code: 'es-ES',
                      languageDescription: 'Español - (España)',
                    },
                  },
                ],
              },
            },
          }),
        100,
      );
    }),
);

mockClient.setRequestHandler(USER_SEARCH_CONFIG_ENABLED_FIELDS_QUERY, () =>
  Promise.resolve({
    data: {
      ccWebUserSearchConfigEnabledFields: {
        fields: [
          {
            enabled: false,
            fieldName: FieldNames.EmailAddress,
            order: 3,
            translationStringId: 'email-address',
            fieldType: FieldTypes.EmailAddress,
          },
          {
            enabled: true,
            fieldName: FieldNames.LeaderName,
            order: 1,
            translationStringId: 'leader-name',
            fieldType: FieldTypes.LeaderName,
          },
          {
            enabled: false,
            fieldName: FieldNames.BusinessUnit,
            order: 0,
            translationStringId: 'business-unit',
            fieldType: FieldTypes.BusinessUnit,
          },
          {
            enabled: true,
            fieldName: FieldNames.EmployeeId,
            order: 2,
            translationStringId: 'employee-id',
            fieldType: FieldTypes.EmployeeId,
          },
          {
            enabled: true,
            fieldName: FieldNames.ParentBusinessUnit,
            order: 4,
            translationStringId: 'parent-business-unit',
            fieldType: FieldTypes.ParentBusinessUnit,
          },
          {
            enabled: true,
            fieldName: FieldNames.JobTitle,
            order: 6,
            translationStringId: 'job-title',
            fieldType: FieldTypes.JobTitle,
          },
          {
            enabled: false,
            fieldName: FieldNames.WorkLocation,
            order: 5,
            translationStringId: 'work-location',
            fieldType: FieldTypes.WorkLocation,
          },
          {
            enabled: true,
            fieldName: FieldNames.WorkLocationCondensed,
            order: 7,
            translationStringId: 'work-location-condensed',
            fieldType: FieldTypes.WorkLocationCondensed,
          },
        ],
      },
    },
  }),
);

mockClient.setRequestHandler(PREFERRED_PRONOUNS_ENABLED, () =>
  Promise.resolve({
    data: {
      ccWebPreferredPronouns: {
        enabled: true,
      },
    },
  }),
);

mockClient.setRequestHandler(
  GET_VIBES_INFO,
  () =>
    new Promise((resolve) => {
      window.setTimeout(() => {
        resolve({
          data: {
            vibeGetDerivedVibeDetails: {
              attributes: {
                text: {
                  welcomeMessage: 'hello',
                  pointsNamePlural: 'Punten',
                  pointsNameSingular: 'Point',
                },
              },
              vibeName: 'something',
              vibeUuid: '1234something',
            },
          },
        });
      }, 1500);
    }),
);

i18n.use(initReactI18next).init({
  lng: 'en',
  resources: {},
  interpolation: {
    prefix: '{',
    suffix: '}',
    escapeValue: false,
  },
});

export const wrapPageElement = (props) => (
  <RadixProvider>
    <ApolloProvider client={mockClient}>
      <ThemeProvider>
        <App {...props} />
      </ThemeProvider>
    </ApolloProvider>
  </RadixProvider>
);
