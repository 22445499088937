import styled from '@emotion/styled';
import { alpha, Fab } from '@mui/material';
import { tannerGray, tannerSemantic } from '../ThemeProvider/colors';

export const extraLargeCss = {
  height: '54px',
  padding: '0 32px 0 32px',
  minWidth: '170px',
  borderRadius: '3px',
};

export const smallCss = {
  minHeight: '24px',
  padding: '0 6px',
  borderRadius: '3px',
};

export const buttonTypeCss = {
  danger: {
    color: tannerSemantic.error.color,
    '&:hover, &:focus-visible': {
      background: tannerSemantic.error.background,
      color: tannerSemantic.error.color,
    },
    '&:disabled': {
      color: tannerSemantic.error.color,
    },
    '&:active': {
      background: '#f6c8c7',
    },
  },
  secondary: {
    '&:hover': {
      background: tannerGray[100],
      color: tannerGray[900],
    },
    '&:disabled': {
      color: tannerGray[900],
    },
    '&:active': {
      background: tannerGray[200],
    },
  },
};

export const LoaderContainer = styled.span`
  position: absolute;
`;

export const ButtonContent = styled.span<{ loading: boolean | undefined }>`
  display: inherit;
  visibility: ${(props) => (props.loading ? 'hidden' : 'inherit')};
  align-items: center;
`;

export const FabContent = styled(ButtonContent)`
  align-items: center;
`;

export const FabButton = styled(Fab)<{ themeColor: string }>`
  color: ${(props) => props.themeColor};
  &.Mui-disabled {
    color: ${(props) => alpha(props.themeColor, 0.6)};
    span .MuiBox-root {
      color: ${(props) => props.themeColor};
    }
  }
`;
