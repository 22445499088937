import styled from '@emotion/styled';
import { tannerWhite, tannerGray } from '../ThemeProvider/colors';
import { useTheme } from '@mui/material/styles';

export const IconWrapper = styled.div`
  display: inline;
  @media (max-width: 480px) {
    display: none;
  }
`;
export const StepIconRoot = styled('div')<{
  ownerState: { active?: boolean; completed?: boolean };
}>(({ ownerState }) => {
  const theme = useTheme();
  const activeStyles = {
    color: theme.palette.primary.main,
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: '50%',
    backgroundColor: 'transparent',
  };
  const completedStyles = {
    backgroundColor: theme.palette.primary.main,
    border: 'none',
    cursor: 'pointer',
    '& .pencilIcon': {
      display: 'none',
    },
    '&:hover': {
      '& .pencilIcon': {
        display: 'inline-block',
      },
      '& .checkIcon, .checkResponsiveIcon': {
        display: 'none',
      },
    },
    '& .checkIcon': {
      display: 'inline-block',
    },
    '& .checkResponsiveIcon': {
      display: 'none',
    },
  };

  return {
    color: tannerGray[600],
    display: 'flex',
    width: 36,
    height: 36,
    alignItems: 'center',
    border: `2px solid ${tannerGray[300]}`,
    borderRadius: '50%',
    justifyContent: 'center',
    fontWeight: 700,
    fontSize: 14,
    backgroundColor: 'transparent',
    ...(ownerState.active && activeStyles),
    ...(ownerState.completed && completedStyles),
    '& .StepIcon-completedIcon': {
      zIndex: 1,
      display: 'flex',
      color: tannerWhite,
      fontWeight: 700,
    },
    '@media (max-width: 480px)': {
      width: 18,
      height: 18,
      fontSize: 10,
      border: `2px solid ${tannerGray[200]}`,
      backgroundColor: tannerGray[200],
      ...(ownerState.active && activeStyles),
      ...(ownerState.completed && {
        ...completedStyles,
        '& .checkIcon': {
          display: 'none',
        },
        '& .checkResponsiveIcon': {
          display: 'inline-block',
        },
      }),
    },
  };
});
