import React, { useState } from 'react';
import { Identity } from './interfaces';
import MobileDrawer from './MobileDrawer';
import MobileHeader from './MobileHeader';

import { MobileContainerProps } from './types';

const MobileContainer: React.FC<MobileContainerProps> = ({
  routerObj: { routerPush, routerRoutes, testRoute },
  multipleBanksObj,
  logoutUrl,
}) => {
  const [navOpen, setNavOpen] = useState(false);

  const handleNavigation = (url: string) => (e: React.MouseEvent) => {
    if (routerPush && routerRoutes.some(testRoute(url))) {
      e.preventDefault();
      routerPush(url);
    }
    setNavOpen(false);
  };

  const selectIdentity = (_: any, identity: Identity | string) => {
    if (typeof identity !== 'string') {
      const url = `/profile/${identity.identityUuid}`;
      if (routerPush && routerRoutes.some(testRoute(url))) {
        routerPush(url);
      } else {
        window.location.assign(url);
      }
    }
    setNavOpen(false);
  };

  return (
    <>
      <MobileDrawer
        navObj={{ navOpen, setNavOpen }}
        handleNavigation={handleNavigation}
        multipleBanksObj={multipleBanksObj}
        logoutUrl={logoutUrl}
        handleonChange={selectIdentity}
      />
      <MobileHeader
        handleNavigation={handleNavigation}
        setNavOpen={setNavOpen}
      />
    </>
  );
};

export default MobileContainer;
