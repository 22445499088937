import React from 'react';
import {
  MenuItemPrimary,
  MenuItemSecondary,
  StyledMenuItem,
  StyledMenuItemLink,
} from './styles';
import { MenuItemProps, Props } from './types';

const MenuItemPrimarySecondary: React.FC<Props> = ({ primary, secondary }) => (
  <>
    <MenuItemPrimary>{primary}</MenuItemPrimary>
    <MenuItemSecondary>{secondary}</MenuItemSecondary>
  </>
);

const MenuItemAll: React.FC<MenuItemProps> = ({
  menuItem: { url, primary, secondary },
  'aria-label': ariaLabel,
  'data-testid': testid,
  handleNavigation,
}) =>
  url ? (
    <StyledMenuItemLink
      aria-label={`${ariaLabel} link`}
      key={primary}
      href={url}
      target="_blank"
      onClick={handleNavigation && handleNavigation(url)}
      data-testid={`${testid}-link`}
    >
      <MenuItemPrimarySecondary primary={primary} secondary={secondary} />
    </StyledMenuItemLink>
  ) : (
    <StyledMenuItem
      aria-label={ariaLabel}
      key={primary}
      disableRipple
      data-testid={`${testid}-item`}
    >
      <MenuItemPrimarySecondary primary={primary} secondary={secondary} />
    </StyledMenuItem>
  );

export default MenuItemAll;
