import React from 'react';
import { Badge, IconButtonProps, IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Tooltip from '../Tooltip';
import useIsBellActive from './hooks/useIsBellActive';
import { Notification as NotificationIcon } from '@octanner/prism-icons';
import { iconButtonOverride } from './styles';

const NotificationBell: React.FC<IconButtonProps> = ({ ...props }) => {
  const { t } = useTranslation();
  const { isActive } = useIsBellActive();

  return (
    <Tooltip
      title={t('prism-header:notifications', 'Notifications')}
      placement="bottom"
    >
      {/* 
    // @ts-ignore */}
      <IconButton
        {...props}
        data-testid="notification-icon"
        component="a"
        href="/notifications"
        aria-label={
          !isActive
            ? t('prism-header:notifications', 'Notifications')
            : t('prism-header:new-notifications', 'New Notifications')
        }
        sx={iconButtonOverride}
      >
        <Badge invisible={!isActive} variant="dot" color="error">
          <NotificationIcon />
        </Badge>
      </IconButton>
    </Tooltip>
  );
};

export default NotificationBell;
