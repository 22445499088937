import styled from '@emotion/styled';
import StepLabel from '../StepLabel';

export const StyledStepLabel = styled(StepLabel)`
  .label {
    @media (max-width: 480px) {
      display: none;
    }
  }
`;
