import { gql, useQuery } from '@apollo/client';
import { ccWebUserSearchConfig } from '../interfaces';

export const USER_SEARCH_CONFIG_ENABLED_FIELDS_QUERY = gql`
  query CcWebUserSearchConfigEnabledFields(
    $input: CCWebUserSearchConfigInput!
  ) {
    ccWebUserSearchConfigEnabledFields(input: $input) {
      fields {
        enabled
        fieldName
        order
        translationStringId
        fieldType
      }
    }
  }
`;

export const useUserSearchConfigFields = (customerUuid?: string) => {
  const { loading, error, data } = useQuery<ccWebUserSearchConfig>(
    USER_SEARCH_CONFIG_ENABLED_FIELDS_QUERY,
    { variables: { input: { customerUuid } }, skip: !customerUuid },
  );
  return { loading, error, data };
};
