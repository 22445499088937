import React, {
  useState,
  useRef,
  ReactElement,
  isValidElement,
  Children,
} from 'react';
import {
  DndContext,
  closestCenter,
  DragStartEvent,
  DragEndEvent,
  MouseSensor,
  useSensor,
  useSensors,
  KeyboardSensor,
  DragOverlay,
  UniqueIdentifier,
} from '@dnd-kit/core';

import {
  verticalListSortingStrategy,
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
} from '@dnd-kit/sortable';
import {
  restrictToVerticalAxis,
  restrictToFirstScrollableAncestor,
} from '@dnd-kit/modifiers';
import { DivContainer, ListElement } from './styles';
import { ChildProps, DraggableRowsProps, Options } from './types';
import { SortableItem } from './SortableItem';

export const DraggableRows: React.FC<DraggableRowsProps> = ({
  variant,
  onChangeValue = (_selected: string[],_orderedList: Options[]) => {},
  onInitialLoad = (_selected: string[],_orderedList: Options[]) => {},
  options = [],
  primaryTextKey = 'primaryKey',
  secondaryTextKey = 'secondaryKey',
  rowId = "id",
  selectedIdList = [],
  children,
  updateOrder = (_updatedOrder: string[]) => {},
}) => {
  const [OptionsList, setOptionsList] = useState<Options[]>(options);
  const [selectedLists, setSelectedLists] = useState<string[]>(
    selectedIdList || [],
  );
  const [focusedIndex, setFocusedIndex] = useState<number | null>(null);
  const [draggingIndex, setDraggingIndex] = useState<number | null>(null);
  const [activeId, setActiveId] = useState<string | null>(null);
  const cardRefs = useRef<(HTMLDivElement | null)[]>([]);

  const childrenArray = Children.toArray(children);

  const selectedListsOnLoad = options
    .filter((item) => item.isToggleSelected)
    .map((item) => item.id);

  const filteredStrings: string[] = selectedListsOnLoad.filter(
    (item): item is string => typeof item === 'string',
  );

  onInitialLoad(filteredStrings, options);

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 0,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
      scrollBehavior: 'auto',
    }),
  );

  const getIndex = (id: UniqueIdentifier) => {
    return variant === 'settings'
      ? OptionsList.findIndex((item) => item.id === id)
      : childrenArray.findIndex(
          (child) => isValidElement(child) && child.props.id === id);
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    setActiveId(null);

    if (active.id !== over?.id) {
      const oldIndex = getIndex(active.id);
      const newIndex = getIndex(over?.id ?? '');

      if (variant === 'settings') {
        const newOrder = arrayMove(OptionsList, oldIndex, newIndex);
        setOptionsList(newOrder);
        updateChangeValue(newOrder);
      } else {
        const currentOrder = getOrderIdForChildren();
        const newOrder = arrayMove(currentOrder, oldIndex, newIndex);
        updateOrder(newOrder);
      }
    }

    setDraggingIndex(null);
  };

  const handleSwitchClick = (
    event: React.ChangeEvent<HTMLInputElement> | undefined,
    index: number,
  ) => {
    if (event && typeof event.stopPropagation === 'function') {
      event.stopPropagation();
    }
    handleToggleOn(event, index);
  };

  const handleDragStart = (event: DragStartEvent) => {
    const { active } = event;
    setActiveId(String(active.id));
    const index = getIndex(active.id);
    if (index !== -1) {
      setDraggingIndex(index);
    }
  };

  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLDivElement>,
    index: number,
  ) => {
    switch (event.key) {
      case 'ArrowUp':
        event.preventDefault();
        if (index > 0) {
          const newOrder = arrayMove(OptionsList, index, index - 1);
          setOptionsList(newOrder);
          setFocusedIndex(index + 2);
          updateChangeValue(newOrder);
        }
        break;
      case 'ArrowDown':
        event.preventDefault();
        if (index < OptionsList.length - 2) {
          const newOrder = arrayMove(OptionsList, index, index + 1);
          setOptionsList(newOrder);
          setFocusedIndex(index + 1);
          updateChangeValue(newOrder);
        }
        break;
      case 'Space':
        event.preventDefault();
        setDraggingIndex(draggingIndex === null ? index : null);
        break;
      default:
        break;
    }
  };

  const handleToggleOn = (
    event:
      | React.MouseEvent
      | React.KeyboardEvent
      | React.ChangeEvent<HTMLInputElement>
      | undefined,
    index: number,
  ) => {
    if (event) {
      event.preventDefault?.();
      event.stopPropagation?.();
    }
    const updatedList = OptionsList.map((item, i) => {
      if (i === index) {
        return { ...item, isToggleSelected: !item.isToggleSelected };
      }
      return item;
    });
    setOptionsList(updatedList);
    updateChangeValue(updatedList);
  };

  const getOrderIdForChildren = () => {
    return childrenArray
      .filter(
        (child): child is ReactElement =>
          isValidElement(child) && child.props.id
      )
      .map((child) => child.props.id);
  };

  const getItemsForSortableContext = () => {
    if (variant === 'flexible') {
      return getOrderIdForChildren();
    }
    return OptionsList.map((option) => option[rowId]);
  };

  const setSelectedListIds = (list: Options[]) => {
    return list.filter((item) => item.isToggleSelected).map((item) => item.id);
  };

  const updateChangeValue = (updatedList: Options[]) => {
    const selectedLists = setSelectedListIds(updatedList);
    const filteredStrings: string[] = selectedLists.filter(
      (item): item is string => typeof item === 'string',
    );
    setSelectedLists(filteredStrings);
    onChangeValue(filteredStrings, updatedList);
  };

  return (
    <DndContext
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
      onDragStart={handleDragStart}
      modifiers={[restrictToVerticalAxis, restrictToFirstScrollableAncestor]}
      sensors={sensors}
    >
      <SortableContext
        items={getItemsForSortableContext()}
        strategy={verticalListSortingStrategy}
      >
        <ListElement role="list">
          {variant === 'settings' &&
            OptionsList.map((option: Options, index) => {
              const isDragging = draggingIndex === index;
              const itemLabel = `${option[primaryTextKey]} ${index + 1} of ${
                OptionsList.length
              }`;
              const keyOption = option[rowId] as string;
              return (
                <DivContainer
                  key={keyOption}
                  data-testid={`sortable-item-${keyOption}`}
                  aria-grabbed={isDragging}
                  aria-labelledby={`sortable-item-label-${keyOption}`}
                  role="listitem"
                  aria-describedby={
                    isDragging
                      ? `You have grabbed ${itemLabel}`
                      : `${itemLabel} is draggable`
                  }
                >
                  <SortableItem
                    ref={(el: HTMLDivElement) => (cardRefs.current[index] = el)}
                    id={keyOption}
                    data-testid={`sortable-item-${keyOption}`}
                    option={option}
                    secondaryText={option[secondaryTextKey] as string}
                    primaryText={option[primaryTextKey] as string}
                    index={index}
                    variant={variant}
                    focusedIndex={focusedIndex}
                    handleToggleOn={(event) => handleToggleOn(event, index)}
                    selectedLists={selectedLists}
                    onKeyDown={handleKeyDown}
                    isDragging={isDragging}
                    handleSwitchClick={handleSwitchClick}
                  >
                    {children}
                  </SortableItem>
                </DivContainer>
              );
            })}
          {variant === 'flexible' &&
            childrenArray.map((child, index) => {
              const isDisabled =
                isValidElement<ChildProps>(child) && child.props.isDisabled;
              return (
                <SortableItem
                  key={index}
                  variant={variant}
                  id={isValidElement(child) && child.props.id}
                  index={index}
                  ref={(el: HTMLDivElement) => (cardRefs.current[index] = el)}
                  isDragging={draggingIndex === index}
                  focusedIndex={focusedIndex}
                  isDisabled={isDisabled}
                >
                  {child}
                </SortableItem>
              );
            })}
        </ListElement>
      </SortableContext>

      <DragOverlay>
        {activeId &&
          (variant === 'settings'
            ? (() => {
                const option = OptionsList.find(
                  (option) => rowId && String(option[rowId]) === activeId);
                if (!option) {
                  return null;
                }

                const index = OptionsList.findIndex(
                  (item) => rowId && String(item[rowId]) === activeId);

                return (
                  <SortableItem
                    id={activeId}
                    ref={(el: HTMLDivElement) => (cardRefs.current[index] = el)}
                    option={option}
                    data-testid={`sortable-item-${option[rowId]}`}
                    handleSwitchClick={handleSwitchClick}
                    secondaryText={option[secondaryTextKey] as string}
                    primaryText={option[primaryTextKey] as string}
                    index={index}
                    onKeyDown={handleKeyDown}
                    variant={variant}
                    handleToggleOn={(event) => handleToggleOn(event, index)}
                    focusedIndex={focusedIndex}
                    selectedLists={selectedLists}
                    isDragging={true}
                  >
                    {children}
                  </SortableItem>
                );
              })()
            : (() => {
                const index = getIndex(activeId);

                return (
                  <SortableItem
                    key={index}
                    variant={variant}
                    id={activeId}
                    index={index}
                    ref={(el: HTMLDivElement) => (cardRefs.current[index] = el)}
                    isDragging={true}
                    focusedIndex={focusedIndex}
                  >
                    {childrenArray[index]}
                  </SortableItem>
                );
              })())}
      </DragOverlay>
    </DndContext>
  );
};

export default DraggableRows;
