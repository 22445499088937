import React, { FC } from 'react';
import Button from '../Button';
import { buttonFocusOverride, StyledSkeleton } from './styles';
import { SecondaryButtonsProps } from './types';
import { useTranslation } from 'react-i18next';
import { SecondaryLinks } from './hooks/useLayoutLinks';

const SecondaryButtons: FC<SecondaryButtonsProps> = ({
  links,
  handleNavigation,
  layoutLoading,
  headerView,
  newTabProps,
}) => {
  const { t } = useTranslation();

  if (layoutLoading) {
    return <StyledSkeleton />;
  }
  return (
    <>
      {headerView &&
        links.map(({ id, translationString, url }) => (
          <Button
            key={id}
            disableRipple
            sx={buttonFocusOverride}
            className="fs-unmask"
            component="a"
            variant="text"
            data-testid={`${translationString}-link`}
            href={url}
            onClick={handleNavigation(url)}
            {...(id === SecondaryLinks.Admin && newTabProps)}
          >
            {t(`prism-header:${translationString}`)}
          </Button>
        ))}
    </>
  );
};

export default SecondaryButtons;
