import React from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import { tannerGray } from '../ThemeProvider/colors';
import { Search as SearchIcon } from '@octanner/prism-icons';
import { AutocompleteRenderInputParams, styled } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Paper from '../Paper';

export const TitleContainer = styled('div')({
  alignItems: 'flex-start',
  display: 'flex',
  flex: '1 1 auto',
  flexDirection: 'column',
  justifyContent: 'center',
  paddingLeft: 8,
});

export const Title = styled('div', { name: 'Title' })<{
  sub?: boolean;
  light?: boolean;
  disabled?: boolean;
}>(({ sub, light, disabled }) => ({
  color: sub || light || disabled ? tannerGray['600'] : '#000000',
  fontSize: sub ? 12 : 14,
  alignSelf: light ? 'center' : undefined,
}));

export const AvatarTitleWrap = styled('div', { name: 'AvatarTitleWrap' })<{
  selected?: boolean;
}>(({ selected = false }) => ({
  display: 'flex',
  flex: '1 1 auto',
  opacity: selected ? 0.5 : 1,
}));

export const AvatarContainer = styled('div', { name: 'AvatarContainer' })<{
  disabled?: boolean;
}>(({ disabled }) => ({
  opacity: disabled ? 0.2 : 1,
}));

export const CheckWrap = styled('div', { name: 'CheckWrap' })({
  width: 24,
  textAlign: 'right',
});

const StyledSpan = styled('span')({
  marginRight: '8px',
  marginLeft: '8px',
  fontSize: 16,
  display: 'flex',
  alignItems: 'center',
});

interface StyledBoxProps extends BoxProps {
  disabled?: boolean;
}

export const StyledBox = styled(Box)<StyledBoxProps>(
  ({ theme, disabled, ...rest }) => ({
    borderRadius: 3,
    display: 'flex',
    padding: 8,
    width: '100%',
    pointerEvents: disabled ? 'none' : 'auto',
  }),
);

export const getSearchInputStyles = (
  disabled: boolean | undefined,
  props: AutocompleteRenderInputParams,
  loading: boolean | undefined,
) => ({
  sx: () =>
    disabled
      ? {
          backgroundColor: tannerGray['100'],
          color: tannerGray['400'],
        }
      : {
          ...(props.size === 'small' && {
            backgroundColor: tannerGray['50'],
          }),
        },
  ...props.InputProps,
  startAdornment: (
    <>
      <StyledSpan>
        {loading ? (
          <CircularProgress size="16px" aria-label="loading" />
        ) : (
          <SearchIcon />
        )}
      </StyledSpan>
      {props.InputProps.startAdornment}
    </>
  ),
});

export const CustomPaper = ({ ...paperProps }) => (
  <Paper sx={{ mt: '4px' }} {...paperProps} />
);
