import React from 'react';
import IconButton from '../IconButton';
import { HeaderCell, HeaderInnerRow, HeaderRow } from './styles';
import { MenuHamburger as MenuHamburgerIcon } from '@octanner/prism-icons';
import { MobileHeaderProps } from './types';
import NotificationBell from './NotificationBell';
import LogoLink from './LogoLink';

const MobileHeader: React.FC<MobileHeaderProps> = ({
  handleNavigation,
  setNavOpen,
}) => {
  return (
    <HeaderRow>
      <HeaderInnerRow>
        <HeaderCell>
          <IconButton onClick={() => setNavOpen(true)} aria-label="open menu">
            <MenuHamburgerIcon />
          </IconButton>
        </HeaderCell>
        <LogoLink handleNavigation={handleNavigation} />
        <HeaderCell>
          <NotificationBell onClick={handleNavigation('/notifications')} />
        </HeaderCell>
      </HeaderInnerRow>
    </HeaderRow>
  );
};

export default MobileHeader;
